import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { Icon } from '../../../../../../shared/Icon/Icon';
import { useDebounce } from '../../../../../../shared/customHooks/useDebounce';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import SurveyQuillEditor from '../../../../../../shared/SurveyQuillEditor/SurveyQuillEditor';
import { returnReversedIndex } from '../../../../../../shared/SurveyQuillEditor/SurveyQuillEditorHelpers';
import { TextFilterDropdownButton } from '../../../helpers/TextFilterDropdownButton/TextFilterDropdownButton';
import { AnswerLayoutValidationErrorMessage } from '../../../helpers/AnswerLayoutValidationErrorMessage/AnswerLayoutValidationErrorMessage';
import { cleanHtmlEntities } from '../../../../../../shared/helpers/cleanHtmlEntities/cleanHtmlEntities';
import { StatementsTableHeader } from './StatementsTableHeader/StatementsTableHeader';
import { StatementsActions } from './StatementsActions/StatementsActions';
import { AnswersActions } from './AnswersActions/AnswersActions';
import SurveyAnswersQuillEditor from '../../../../../../shared/SurveyQuillEditor/SurveyAnswersQuillEditor';
import AdvancedStatementsTable from './AdvancedStatementsTable/AdvancedStatementsTable';
import { fetchGetJson as getSurveyStructure } from '../../../../../../../services/services';

export const SurveyElementsTable = ({ token, subQuesIndex, theData, subquestion, onDragEnd, onAddElement, onAdvancedEdit, editingLanguage, showLogic, onSaveChanges, showFilterBuilderHandler }) => {
  const dispatch = useDispatch()
  const activeRef = useRef()
  const params = useParams()
  const [showStatementsAdvancedTable, setShowStatementsAdvancedTable] = useState(false)
  const [listItemsDropdown, setListItemsDropdown] = useState([])
  const [statementsSort, setStatementsSort] = useState('')
  const [answersSort, setAnswersSort] = useState('')
  const selectedElementId = useSelector(theState => (theState.surveyInitialDataReducer.selectedElement.id));
  const { selectedElement } = useSelector((theState) => (theState.surveyInitialDataReducer));
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  const [clickedField, setClickedField] = useState("");

  useEffect(() => {
    if (theData.optionsType && (theData.optionsType.type === 'h' || theData.optionsType.type === 'r' || theData.optionsType.type === 'hgroup' || theData.optionsType.type === 'rgroup')) {
      activeRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    }
  }, [theData.optionsType])

  const renderItem = (type, item, row, rowIndex, rowTextIndex) => {
    const itemText = item.text;
    switch (itemText) {
      case "textType":
        return (
          <div className="d-flex flex-column">
            <div className="dropdown-info-item">
              <p className="text-muted mb-1 px-4 mr-1 mt-1 small">Text option</p>
            </div>

            <button type="button" onClick={() => onDropdownItemClick(type, 'regular', rowIndex, rowTextIndex)} className="dropdown-item d-flex align-items-center ">
              <span><Icon type="checkmark" className={`mr-2 fill-primary ${row.attributes?.textType === 'regular' || row.attributes?.textType === undefined ? '' : 'invisible'}`} /></span>
              <span>Regular</span>
            </button>

            <button type="button" onClick={() => onDropdownItemClick(type, 'do', rowIndex, rowTextIndex)} className="dropdown-item d-flex align-items-center ">
              <span><Icon type="checkmark" className={`mr-2 fill-primary ${row.attributes?.textType === 'do' ? '' : 'invisible'}`} /></span>
              <span>Instruction</span>
            </button>

            <button type="button" onClick={() => onDropdownItemClick(type, 'say', rowIndex, rowTextIndex)} className="dropdown-item d-flex align-items-center ">
              <span><Icon type="checkmark" className={`mr-2 fill-primary ${row.attributes?.textType === 'say' ? '' : 'invisible'}`} /></span>
              <span>Supplementary</span>
            </button>
          </div>
        )

      case "textFilter":
        return (
          <Fragment>
            <div style={{ height: '1px' }} className="w-100 my-1 bg-light" />
            <button
              type='button'
              onClick={() => onDropdownItemClick(type, 'textFilter', rowIndex, rowTextIndex)}
              className="dropdown-item d-flex align-items-center">
              <span>Add text filter</span>
            </button>
          </Fragment>
        )

      case "addText":
        return (
          <Fragment>
            <button
              type='button'
              onClick={() => onDropdownItemClick(type, 'addText', rowIndex)}
              className="dropdown-item d-flex align-items-center">
              <span>Add new text</span>
            </button>
          </Fragment>
        )
      case "deleteText":
        return (
          <Fragment>
            <div style={{ height: '1px' }} className="w-100 my-1 bg-light" />
            <button
              type='button'
              disabled={theData.selectedItem.published}
              onClick={() => onDropdownItemClick(type, 'deleteText', rowIndex, rowTextIndex)}
              className="dropdown-item d-flex align-items-center text-danger">
              <span>Delete text</span>
            </button>
          </Fragment>
        )
    }
  }

  const onDropdownItemClick = (type, actionType, index, textIndex) => {
    if (type === 'r') {
      onStatementDropdownItemClick(actionType, index, textIndex)
    } else {
      onAnswerDropdownItemClick(actionType, index, textIndex)
    }
  }

  const onStatementDropdownItemClick = (actionType, rowIndex, rowTextIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (actionType !== 'addText' && actionType !== 'textFilter' && actionType !== "deleteText") {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].elements[rowTextIndex].attributes.textType = actionType
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    } else {
      if (actionType === 'addText') {
        const values = {}
        theData.originalData.languages.map((el) => el.code).forEach(el => values[el] = "")
        updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].elements.push({ type: "text", attributes: {}, textValues: values, metadata: null })
        dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      } else if (actionType === 'textFilter') {
        showFilterBuilderHandler('filter', 'r', rowIndex, rowTextIndex)
        dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      } else if (actionType === "deleteText") {
        onDeleteStatementText(rowIndex, rowTextIndex)
      }
    }

    if (actionType !== 'textFilter') {
      dispatch({ type: 'SAVE_QUESTION' })
    }
  }

  const onAnswerDropdownItemClick = (actionType, columnIndex, columnTextIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (actionType !== 'addText' && actionType !== 'textFilter' && actionType !== "deleteText") {
      updatedSelectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[columnIndex].elements[columnTextIndex].attributes.textType = actionType
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    } else {
      if (actionType === 'addText') {
        const values = {}
        theData.originalData.languages.map((el) => el.code).forEach(el => values[el] = "")
        updatedSelectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[columnIndex].elements.push({ type: "text", attributes: {}, textValues: values, metadata: null })
        dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      } else if (actionType === 'textFilter') {
        showFilterBuilderHandler('filter', 'h', columnIndex, columnTextIndex)
        dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      } else if (actionType === "deleteText") {
        onDeleteAnswerText(columnIndex, columnTextIndex)
      }
    }
  }

  const returnTextTypeText = (text) => {
    if (text === 'say') {
      return 'Supplementary'
    } if (text === 'do') {
      return 'Instruction'
    }
    return ' '

  }

  const onDeleteWholeRowColumn = (index, isRow) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))

    if (isRow === true) {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements.splice(index, 1)
    } else {
      updatedSelectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements.splice(index, 1)
    }

    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onDeleteStatementText = (rowIndex, rowTextIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex]?.elements?.length > 1) {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].elements.splice(rowTextIndex, 1)
    } else {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements.splice(rowIndex, 1)
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onDeleteAnswerText = (columnIndex, columnTextIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (updatedSelectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[columnIndex]?.elements?.length > 1) {
      updatedSelectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[columnIndex].elements.splice(columnTextIndex, 1)
    } else {
      updatedSelectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements.splice(columnIndex, 1)
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const deleteListItem = (rowIndex, type) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (type === 'r' || type === "rc") {
      const elementsArray = updatedSelectedItem.elements.find(el => el.type === 'rgroup').elements
      elementsArray.splice(rowIndex, 1)
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const disableListItem = (index, direction) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (direction === "row") {
      const foundList = updatedSelectedItem.elements.find(el => el.type === 'rgroup').elements[index]
      if (foundList.attributes['miext:disabled']) {
        delete foundList.attributes['miext:disabled']
      } else {
        foundList.attributes['miext:disabled'] = true
      }
    } else if (direction === "column") {
      const foundListColumn = updatedSelectedItem.elements[theData.selectedSubquestion].elements.find(el => el.type === "hgroup").elements[index]
      if (foundListColumn.attributes['miext:disabled']) {
        delete foundListColumn.attributes['miext:disabled']
      } else {
        foundListColumn.attributes['miext:disabled'] = true
      }
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onChangeTableOption = (value, option, elementType, elementIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    let element = null
    let updatedAttributes = null

    if (elementType === 'r') {
      element = updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[elementIndex]
      updatedAttributes = element.attributes
    } else {
      element = updatedSelectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[elementIndex]
      updatedAttributes = element.attributes
    }

    if (option === 'exclusive') {
      element.attributes = { ...updatedAttributes, exclusive: value ? 'yes' : 'no' }
    } else if (option === 'openRow') {
      if (!value) {
        if (element.attributes.lines) {
          delete element.attributes.lines
        }
        if (element.attributes.openlength) {
          delete element.attributes.openlength
        }
      }
      element.type = value === true ? 'other' : 'r'
    } else if (option === 'rot') {
      element.attributes = { ...updatedAttributes, rot: value ? 'n' : '' }
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onTextChangeHandler = (value, elementType, elementIndex, elementTextIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    let element = null

    if (elementType === 'r' || elementType === 'rc') {
      element = updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[elementIndex].elements[elementTextIndex]
    } else {
      element = updatedSelectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[elementIndex].elements[elementTextIndex]
    }
    element.textValues[editingLanguage] = cleanHtmlEntities(value);
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onChangeCode = (value, type, itemIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (type === 'r') {
      updatedSelectedItem.elements.find(el => el.type === 'rgroup').elements[itemIndex].attributes.code = value
    } else if (type === 'h') {
      updatedSelectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[itemIndex].attributes.code = value
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const showRowFilterBuilder = (option, row, rowIndex) => {
    const element = JSON.parse(JSON.stringify(theData.originalSelectedItem))
    dispatch({ type: 'SET_SELECTED_ELEMENT', payload: { ...row, index: rowIndex } })
    getSurveyStructure(`su/projects/${params.name}/surveys/${params.survey}/structure`, token)
      .then((res) => {
        if (res && !res.error && !res.message) {
          dispatch({
            type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: {
              display: true,
              filterData: res,
              filterBuilderOptionId: option,
              combineFilterData: element.metadata?.filterData ? element.metadata.filterData : []
            }
          })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
        }
      })
  }

  const replaceList = (e, index, group) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (group === "statement") {
      const filtered = updatedSelectedItem.elements.find(el => el.type === "rgroup").elements.filter(el => el.type === 'listref')
      const found = filtered.find(el => el.attributes.idref === e.item.text)
      if (found) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "List already exists" } })
      } else {
        updatedSelectedItem.elements.find(el => el.type === "rgroup").elements[index].attributes.idref = e.item.text
      }
    }
    else if (group === "answer") {
      const filtered = updatedSelectedItem.elements[0].elements.find(el => el.type === "hgroup").elements.filter(el => el.type === 'listref')
      const found = filtered.find(el => el.attributes.idref === e.item.text)
      if (found) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "List already exists" } })
      } else {
        updatedSelectedItem.elements[0].elements.find(el => el.type === "hgroup").elements[index].attributes.idref = e.item.text
      }
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  useEffect(() => {
    const lists = theData.originalData.lists
    const array = []
    lists.map(el => {
      return array.push({ text: el.attributes.id })
    })
    setListItemsDropdown(array)
  }, [theData.originalData.lists])

  const openTheListModalView = (name) => {
    const index = theData.originalData.lists.findIndex(el => el.attributes.id === name)
    if (index === -1) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "This list has been deleted and cannot be opened" } })
    } else {
      dispatch({ type: 'SET_SELECTED_LIST_INDEX', payload: index });
      dispatch({ type: 'OPEN_LIST_MODAL_VIEW_DIALOG', payload: true })
    }
  };

  const checkSubquestions = theData.selectedItem.elements.filter((item) => item.type === 'subq')

  const isSingleMultiQuestion = theData.selectedItem?.type === "ques" &&
    (checkSubquestions[theData.selectedSubquestion]?.attributes.type === "n" || checkSubquestions[theData.selectedSubquestion]?.attributes.type === "m");

  return (
    <Fragment>
      {
        showStatementsAdvancedTable &&
        <AdvancedStatementsTable
          showLogic={showLogic}
          onDragEnd={onDragEnd}
          subquestion={subquestion}
          subQuesIndex={subQuesIndex}
          onAddElement={onAddElement}
          onSaveChanges={onSaveChanges}
          editingLanguage={editingLanguage}
          showFilterBuilderHandler={showFilterBuilderHandler}
          renderItem={renderItem}
          onChangeCode={onChangeCode}
          returnTextTypeText={returnTextTypeText}
          onChangeTableOption={onChangeTableOption}
          onTextChangeHandler={onTextChangeHandler}
          showRowFilterBuilder={showRowFilterBuilder}
          onDeleteStatementText={onDeleteStatementText}
          onHide={() => setShowStatementsAdvancedTable(false)}
        />
      }
      <div className="d-flex flex-column gap-md">
        {/* Statements title and actions */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className='m-0 strong'>{subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm' ? 'Statements' : 'Answers'}</span>
            {
              theData.selectedItem.elements.find(el => el.type === 'rgroup').errorMessage !== "" &&
              <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                <AnswerLayoutValidationErrorMessage errorMessage={theData.selectedItem.elements.find(el => el.type === 'rgroup').errorMessage} className="ml-2" />
              </Tooltip>
            }
          </div>
          <StatementsActions
            statementsSort={statementsSort}
            setStatementsSort={setStatementsSort}
            subQuesIndex={subQuesIndex}
            subquestion={subquestion}
            onAdvancedEdit={onAdvancedEdit}
            setShowStatementsAdvancedTable={setShowStatementsAdvancedTable}
            listItemsDropdown={listItemsDropdown}
            onAddElement={onAddElement}
          />
        </div>

        {/* Statements table */}
        <div className="statements-table w-100 pb-2 pt-1 px-0 rounded mb-1">
          <div className="answer-layout-table">
            <StatementsTableHeader
              subQuesIndex={subQuesIndex}
              isSingleMultiQuestion={isSingleMultiQuestion}
              subquestionAttributesType={subquestion.attributes.type}
            />

            <DragDropContext onDragEnd={(result) => onDragEnd(result, subQuesIndex)}>
              <Droppable droppableId="rows" type="rows">{
                (provided) => (
                  <div className='px-4' ref={provided.innerRef}>
                    {
                      theData.selectedItem.elements?.find(el => el.type === 'rgroup').elements.length > 0 &&
                      theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.filter((row) => row.type === 'listref' || row.type === "rc" || row.elements?.length > 0)
                        .map((row, rowIndex) => (
                          <Draggable key={row.type === "r" || row.type === "other" || row.type === "rc" ? row.id : row.attributes.idref} draggableId={row.type === "r" || row.type === "other" || row.type === "rc" ? row.id : row.attributes.idref} index={rowIndex}>{
                            (provided, snapshot) => (
                              <Fragment>
                                {row.type === "r" || row.type === 'other' || row.type === "rc" ?
                                  <div key={row.id} ref={provided.innerRef} {...provided.draggableProps} className={`list-group-container${snapshot.isDragging ? ' dragging' : ''}${selectedElementId === row.id ? ' active' : ''} ${row.disabled ? "disabled" : ""}`} >
                                    <span className={`${row.disabled ? "row-disabled" : "d-none"}`} onClick={() => dispatch({ type: 'SET_SELECTED_ELEMENT', payload: { ...row, index: rowIndex } })} />
                                    <div className="drag-handle" {...provided.dragHandleProps} tabIndex="-1" >
                                      <Icon type="draggable-alt" />
                                    </div>
                                    <div className='w-100'>
                                      <div className="d-flex flex-column align-items-center border-right h-100">
                                        {row.elements.length > 0 && row.elements.filter(rowText => rowText.textValues).map((rowText, rowTextIndex) => (
                                          <div key={rowTextIndex} className="list-group-item statement-list-group-item border-top border-bottom-0 border-left-0 border-right-0 gap-sm w-100">
                                            <div style={{ cursor: 'text' }} className="d-flex align-items-center flex-grow-1" onClick={() => { dispatch({ type: 'SET_SELECTED_ELEMENT_COMMENTS', payload: false }); dispatch({ type: 'SET_SELECTED_ELEMENT', payload: { ...row, index: rowIndex } }) }}>
                                              {row.type === "rc" ?
                                                <Fragment>
                                                  <div style={{ cursor: 'text' }} className="d-flex align-items-center flex-grow-1" onClick={() => dispatch({ type: 'SET_SELECTED_ELEMENT', payload: {} })}>
                                                    <input
                                                      type="text"
                                                      style={{ maxHeight: 'unset' }}
                                                      className="form-control border-0 shadow-none text-answer bg-secondary"
                                                      disabled={row.disabled}
                                                      value={rowText.textValues[editingLanguage]}
                                                      onChange={(e) => onTextChangeHandler(e.target.value, 'rc', rowIndex, rowTextIndex)}
                                                      placeholder="Enter answer header"
                                                    />
                                                  </div>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                  {!theData.htmlMode ?
                                                    clickedField === rowIndex ?
                                                      <SurveyAnswersQuillEditor
                                                        token={token}
                                                        value={rowText.textValues[editingLanguage]}
                                                        readOnly={row.disabled}
                                                        setValue={(e) => onTextChangeHandler(e, "r", rowIndex, rowTextIndex)}
                                                        orderNumber={returnReversedIndex(theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.filter((row) => row.type === 'listref' || row.elements?.length > 0).length, rowIndex)}
                                                        selectedItemId={theData.selectedItem.id}
                                                        placeholder={rowText.attributes?.textType === 'do' ? 'Enter instruction text...' : rowText.attributes?.textType === 'say' ? 'Enter supplementary text...' : subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm' ? 'Enter statement text...' : 'Enter answer text...'}
                                                        classes={`${editingLanguage === 'ar' ? 'direction-rtl' : ''}`}
                                                      />
                                                      :
                                                      <input
                                                        id={`${rowText}_${rowIndex}`}
                                                        type="text"
                                                        style={{ maxHeight: 'unset' }}
                                                        className={`${editingLanguage === 'ar' ? 'direction-rtl' : ''} form-control border-0 shadow-none text-answer bg-white`}
                                                        disabled={row.disabled}
                                                        value={rowText.textValues[editingLanguage]?.replace(/(<([^>]+)>)/gi, '')}
                                                        onClick={() => setClickedField(rowIndex)}
                                                        readOnly
                                                        placeholder={rowText.attributes?.textType === 'do' ? 'Enter instruction text...' : rowText.attributes?.textType === 'say' ? 'Enter supplementary text...' : subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm' ? 'Enter statement text...' : 'Enter answer text...'}
                                                      />
                                                    :
                                                    <input
                                                      type="text"
                                                      style={{ maxHeight: 'unset' }}
                                                      className="form-control border-0 shadow-none text-answer"
                                                      disabled={row.disabled}
                                                      value={rowText.textValues[editingLanguage]}
                                                      onChange={(e) => onTextChangeHandler(e.target.value, 'r', rowIndex, rowTextIndex)}
                                                      placeholder={rowText.attributes?.textType === 'do' ? 'Enter instruction text...' : rowText.attributes?.textType === 'say' ? 'Enter supplementary text...' : subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm' ? 'Enter statement text...' : 'Enter answer text...'}
                                                    />
                                                  }
                                                </Fragment>
                                              }
                                            </div>

                                            {
                                              row.errorMessage !== "" &&
                                              <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                                <AnswerLayoutValidationErrorMessage errorMessage={row.errorMessage} className="mr-2" />
                                              </Tooltip>
                                            }

                                            {row.type !== "rc" && (
                                              <DropdownButton
                                                text={returnTextTypeText(rowText.attributes?.textType)}
                                                items={[{ text: "textType" }, { text: 'textFilter' }, { text: "addText" }, { text: "deleteText" }]}
                                                className={`p-0 mr-2 btn-secondary ${returnTextTypeText(rowText.attributes?.textType) === ' ' ? 'row-more' : 'pl-2'}`}
                                                parentClass="d-flex align-items-center"
                                                renderItem={(e) => renderItem('r', e, rowText, rowIndex, rowTextIndex)}
                                              />
                                            )}

                                            {
                                              rowText.attributes?.filter && showLogic && row.type !== "rc" &&
                                              <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                                <div className="d-flex align-items-center h-100 mr-2">
                                                  <TextFilterDropdownButton
                                                    type="r"
                                                    text={rowText}
                                                    showLogic={showLogic}
                                                    itemIndex={rowIndex}
                                                    itemTextIndex={rowTextIndex}
                                                    showFilterBuilderHandler={showFilterBuilderHandler} />
                                                </div>
                                              </Tooltip>
                                            }
                                          </div>
                                        ))
                                        }
                                      </div>
                                    </div>

                                    {row.type !== "rc" && (
                                      <Fragment>
                                        {
                                          (subquestion.attributes.type === 'n' || subquestion.attributes.type === 'm') &&
                                          <div id='code' className='code-input d-flex align-items-center border-right'>
                                            <input id={rowIndex} value={row.attributes?.code} onChange={(e) => onChangeCode(e.target.value, 'r', rowIndex)} type="input" min={1} className="form-control text-center mx-auto py-2" />
                                          </div>
                                        }
                                        <div id='checkbox' className='d-flex align-items-center w-40 border-right'>
                                          <input id={`${rowIndex}_exclusive`} checked={row.attributes?.exclusive === 'yes'} onChange={(e) => onChangeTableOption(e.target.checked, 'exclusive', 'r', rowIndex)} type="checkbox" className='form-control mx-auto shadow-none' />
                                        </div>
                                        <div id='checkbox' className='d-flex align-items-center w-40 border-right'>
                                          <input id={`${rowIndex}_rot`} checked={row.attributes?.rot?.toLowerCase() === 'n'} onChange={(e) => onChangeTableOption(e.target.checked, 'rot', 'r', rowIndex)} type="checkbox" className='form-control mx-auto shadow-none' />
                                        </div>
                                        {
                                          !(subQuesIndex === 0 && subquestion.attributes.type === 'f') &&
                                          <div id='checkbox' className='d-flex align-items-center w-40 border-right'>
                                            <input id={`${rowIndex}_other`} checked={row.type === 'other'} onChange={(e) => onChangeTableOption(e.target.checked, 'openRow', 'r', rowIndex)} type="checkbox" className='form-control mx-auto shadow-none' />
                                          </div>
                                        }
                                        <div id={`${rowIndex}_filter`} className={`${isSingleMultiQuestion ? 'd-none' : 'd-flex'} align-items-center justify-content-center w-40 border-right`}>
                                          <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                            <div className="d-flex justify-content-center align-items-center">
                                              <button
                                                type='button'
                                                onClick={() => showRowFilterBuilder('filter', row, rowIndex)}
                                                title={row?.attributes?.filter && showLogic ? `${row.attributes.filter}` : "Add filter"}
                                                className={`btn ${row.attributes?.filter && showLogic ? "btn-survey-light-blue text-survey-dark-blue" : "btn-secondary"} py-1 px-2 filter`}>
                                                F
                                              </button>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </Fragment>
                                    )}

                                    <div className='d-flex align-items-center justify-content-center w-40'>
                                      <Tooltip anchorElement="target" position="top">
                                        <button
                                          type='button'
                                          title={row.type !== "rc" ? "Delete row" : "Delete header"}
                                          disabled={theData.selectedItem.elements.find(el => el.type === 'rgroup').elements[rowIndex].attributes['miext:id']}
                                          onClick={() => onDeleteWholeRowColumn(rowIndex, true)}
                                          className="btn p-1 delete flex-shrink-0"
                                        >
                                          <Icon type="delete-alt" className='pe-none' />
                                        </button>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  :
                                  <div key={rowIndex} ref={provided.innerRef} {...provided.draggableProps} className="list-group-container">
                                    <div className="drag-handle" {...provided.dragHandleProps} tabIndex="-1" >
                                      <Icon type="draggable-alt" />
                                    </div>
                                    <div className="d-flex w-100">
                                      <div className="list-group flex-grow-1">
                                        <div className={`list-group-item statement-list-group-item align-items-center justify-content-between rounded-0 w-100 h-100 ${theData.selectedItem.elements.find(el => el.type === 'rgroup').elements[rowIndex].attributes['miext:disabled'] ? "list-disabled" : ""}`}>
                                          <div onClick={() => openTheListModalView(row.attributes.idref)} className="d-flex px-2 h-100 w-100 cursor-pointer">
                                            {theData.originalData.lists.find(el => el.attributes.id === row.attributes.idref) ? "" :
                                              <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                                <AnswerLayoutValidationErrorMessage errorMessage="This list has been deleted" className="mr-2" />
                                              </Tooltip>
                                            }
                                            <div className="d-flex align-items-center w-100">
                                              <span className='lists-icon-elements-table'><Icon fill="white" type="lists" /></span>
                                              {row.type === "listref" ? <span>{row.attributes.idref}</span> : null}
                                            </div>
                                          </div>
                                          <div className='d-flex h-100'>
                                            <div className='d-flex align-items-center border-right h-100'>
                                              <DropdownButton
                                                className={`btn-secondary row-more p-0 mr-2 ${theData.selectedItem.published && "d-none"}`}
                                                parentClass="d-flex align-items-center"
                                                hideChevron={true}
                                                icon="chevron-mini-down-alt"
                                                onItemClick={e => replaceList(e, rowIndex, "statement")}
                                                items={listItemsDropdown}
                                              />
                                            </div>
                                            {theData.selectedItem.published && theData.selectedItem.elements.find(el => el.type === 'rgroup').elements[rowIndex].attributes['miext:id'] ?
                                              <div className='d-flex align-items-center justify-content-center w-40'>
                                                <Tooltip anchorElement="target" position="top">
                                                  <button
                                                    type='button'
                                                    title={theData.selectedItem.elements.find(el => el.type === 'rgroup').elements[rowIndex].attributes['miext:disabled'] ? "Enable list" : "Disable list"}
                                                    onClick={() => disableListItem(rowIndex, "row")}
                                                    className="p-1 btn rounded-0 delete"
                                                  >
                                                    <Icon type={theData.selectedItem.elements.find(el => el.type === 'rgroup').elements[rowIndex].attributes['miext:disabled'] ? "survey-list-hide" : "survey-list-show"} className='pe-none' />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                              :
                                              <div className='d-flex align-items-center justify-content-center w-40'>
                                                <Tooltip anchorElement="target" position="top">
                                                  <button
                                                    type='button'
                                                    title='Delete list'
                                                    disabled={theData.selectedItem.elements.find(el => el.type === 'rgroup').elements[rowIndex].attributes['miext:id']}
                                                    onClick={() => deleteListItem(rowIndex, "r")}
                                                    className="btn p-1 delete flex-shrink-0">
                                                    <Icon type="delete-alt" className='pe-none' />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </Fragment>
                            )}
                          </Draggable>
                        ))
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {
            theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.length === 0 &&
            <div className='no-row'>
              <button
                type='button'
                className="btn"
                onClick={() => onAddElement('r', subQuesIndex)}
                disabled={theData.selectedItem.disabled || subquestion.disabled}>
                {
                  subquestion?.attributes?.type === 'rn' || subquestion?.attributes?.type === 'rm' ?
                    'Click here to add a statement...' : 'Click here to add an answer...'
                }
              </button>
            </div>
          }
        </div>

        {/* Answers table */}
        {
          subquestion.elements.find(el => el.type === 'hgroup') &&
          <Fragment>
            {/* Answers title and actions */}
            <div className="d-flex justify-content-between align-items-center mb-2 mt-4">
              <div className="d-flex align-items-center">
                <span className='m-0 strong'>Answers</span>
                {
                  subquestion.elements.find(el => el.type === 'hgroup').errorMessage !== "" &&
                  <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                    <AnswerLayoutValidationErrorMessage errorMessage={subquestion.elements.find(el => el.type === 'hgroup').errorMessage} className="ml-2" />
                  </Tooltip>
                }
              </div>
              <AnswersActions
                answersSort={answersSort}
                setAnswersSort={setAnswersSort}
                subQuesIndex={subQuesIndex}
                subquestion={subquestion}
                onAdvancedEdit={onAdvancedEdit}
                onAddElement={onAddElement}
                listItemsDropdown={listItemsDropdown}
              />
            </div>
            {/* Answers table */}
            <div className="answers-table w-100 pb-2 pt-1 px-0 rounded mb-5">
              <div className="answer-layout-table">
                <div className='d-flex w-100 pl-4 py-2 table-header'>
                  <p className='d-flex w-100 align-items-center m-0'>
                    Text
                  </p>
                  {
                    (subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm') &&
                    <div className='code d-flex align-items-center justify-content-center'><span>Code</span></div>
                  }
                  <div className="px-2">
                    <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                      <span className='icon' title='Exclusive'><Icon type="answer-layout-exclusive" /></span>
                    </Tooltip>
                  </div>
                </div>
                <DragDropContext onDragEnd={(result) => onDragEnd(result, subQuesIndex)}>
                  <Droppable droppableId="columns" type="columns">{
                    (provided) => (
                      <div className='px-4' ref={provided.innerRef}>
                        {
                          subquestion.elements?.find(el => el.type === 'hgroup').elements.length > 0 &&
                          subquestion.elements.find(el => el.type === 'hgroup').elements.filter(column => column.type === 'listref' || column.elements?.length > 0)
                            .map((column, columnIndex) => (
                              <Draggable key={column.type === 'h' ? column.id : column.attributes.idref} draggableId={column.type === 'h' ? column.id : column.attributes.idref} index={columnIndex}>{
                                (provided, snapshot) => (
                                  <Fragment>
                                    {column.type === 'h' ?
                                      <div key={column.id} ref={provided.innerRef} {...provided.draggableProps} className={`list-group-container${snapshot.isDragging ? ' dragging' : ''}${selectedElementId === column.id ? ' active' : ''} ${column.disabled ? "disabled" : ""}`}>
                                        <span className={`${column.disabled ? "row-disabled" : "d-none"}`} onClick={() => dispatch({ type: 'SET_SELECTED_ELEMENT', payload: { ...column, index: columnIndex } })} />
                                        <div className="drag-handle" {...provided.dragHandleProps} tabIndex="-1" >
                                          <Icon type="draggable-alt" />
                                        </div>
                                        <div className="w-100">
                                          <div className="d-flex align-items-center border-right h-100">
                                            <ul className="list-group flex-grow-1">
                                              {column.elements.length > 0 && column.elements.filter(columnText => columnText.textValues).map((columnText, columnTextIndex) => (
                                                <li key={columnTextIndex} className="d-flex align-items-center justify-content-center w-40list-group-item statement-list-group-item border-top border-bottom-0 border-left-0 border-right-0">
                                                  <div style={{ cursor: 'text' }} className="d-flex align-items-center w-100" onClick={() => dispatch({ type: 'SET_SELECTED_ELEMENT', payload: { ...column, index: columnIndex } })}>
                                                    {!theData.htmlMode ?
                                                      <SurveyQuillEditor
                                                        token={token}
                                                        value={columnText.textValues[editingLanguage]}
                                                        readOnly={column.disabled}
                                                        setValue={(e) => onTextChangeHandler(e, "h", columnIndex, columnTextIndex)}
                                                        orderNumber={returnReversedIndex(subquestion.elements.find(el => el.type === 'hgroup').elements.filter(column => column.type === 'listref' || column.elements?.length > 0).length, columnIndex)}
                                                        selectedItemId={theData.selectedItem.id}
                                                        placeholder={columnText.attributes?.textType === 'do' ? 'Enter instruction text...' : columnText.attributes?.textType === 'say' ? 'Enter supplementary text...' : 'Enter answer text...'}
                                                        classes={`${editingLanguage === 'ar' ? 'direction-rtl' : ''}`} />
                                                      :
                                                      <input
                                                        type="text"
                                                        style={{ maxHeight: 'unset' }}
                                                        className='form-control border-0 shadow-none text-answer'
                                                        disabled={column.disabled}
                                                        value={columnText.textValues[editingLanguage]}
                                                        onChange={(e) => onTextChangeHandler(e.target.value, 'h', columnIndex, columnTextIndex)}
                                                        placeholder={columnText.attributes?.textType === 'do' ? 'Enter instruction text...' : columnText.attributes?.textType === 'say' ? 'Enter supplementary text...' : 'Enter answer text...'} />
                                                    }
                                                  </div>
                                                  {
                                                    column.errorMessage !== "" &&
                                                    <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                                      <AnswerLayoutValidationErrorMessage errorMessage={column.errorMessage} className="mr-2" />
                                                    </Tooltip>
                                                  }
                                                  <DropdownButton
                                                    text={returnTextTypeText(columnText.attributes.textType)}
                                                    items={[{ text: "textType" }, { text: 'textFilter' }, { text: "addText" }, { text: "deleteText" }]}
                                                    className={`btn-secondary p-0 mr-2 ${returnTextTypeText(columnText.attributes.textType) === ' ' ? 'row-more' : 'pl-2'}`}
                                                    parentClass="d-flex align-items-center"
                                                    renderItem={(e) => renderItem('h', e, columnText, columnIndex, columnTextIndex)}
                                                  />
                                                  {
                                                    columnText.attributes?.filter && showLogic &&
                                                    <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                                      <div className="d-flex align-items-center h-100 mr-2">
                                                        <TextFilterDropdownButton
                                                          type="h"
                                                          text={columnText}
                                                          showLogic={showLogic}
                                                          itemIndex={columnIndex}
                                                          itemTextIndex={columnTextIndex}
                                                          showFilterBuilderHandler={showFilterBuilderHandler} />
                                                      </div>
                                                    </Tooltip>
                                                  }
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>
                                        {
                                          (subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm') &&
                                          <div id='code' className='code-input d-flex align-items-center border-right'>
                                            <input value={column.attributes.code} onChange={(e) => onChangeCode(e.target.value, 'h', columnIndex)} type="input" min={1} className="form-control text-center mx-auto py-2" />
                                          </div>
                                        }
                                        <div id='checkbox' className='d-flex align-items-center w-40 border-right'>
                                          <input checked={column.attributes?.exclusive === 'yes' ? true : false} onChange={(e) => onChangeTableOption(e.target.checked, 'exclusive', 'h', columnIndex)} type="checkbox" className='form-control mx-auto shadow-none' />
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center w-40'>
                                          <Tooltip anchorElement="target" position="top">
                                            <button type="button"
                                                title="Delete row"
                                                disabled={theData.selectedItem.elements.filter(el => el.type === 'subq')[subQuesIndex].elements.find(el => el.type === 'hgroup').elements[columnIndex].attributes['miext:id']}
                                                onClick={() => onDeleteWholeRowColumn(columnIndex, false)}
                                                className="btn p-1 delete flex-shrink-0">
                                              <Icon type="delete-alt" className='pe-none' />
                                            </button>
                                          </Tooltip>
                                        </div>
                                      </div>
                                      :
                                      <div className='list-group-container' key={column.id} ref={provided.innerRef} {...provided.draggableProps}>
                                        <div className="drag-handle" {...provided.dragHandleProps} tabIndex="-1" >
                                          <Icon type="draggable-alt" />
                                        </div>
                                        <div className="d-flex w-100">
                                          <div className="list-group flex-grow-1">
                                            <div className={`list-group-item statement-list-group-item align-items-center justify-content-between w-100 ${subquestion.elements.find(el => el.type === 'hgroup').elements[columnIndex].attributes['miext:disabled'] ? "bg-light cursor-pointer" : ""}`}>
                                              <div onClick={() => openTheListModalView(column.attributes.idref)} className="d-flex align-items-center py-1 px-2 cursor-pointer">
                                                {theData.originalData.lists.find(el => el.attributes.id === column.attributes.idref) ? "" :
                                                  <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                                    <AnswerLayoutValidationErrorMessage errorMessage="This list has been deleted" className="mr-2" />
                                                  </Tooltip>
                                                }
                                                <div className="d-flex align-items-center w-100">
                                                  <span className='lists-icon-elements-table'><Icon className="lists-icon-fill-elements-table" type="lists" fill="white" /></span>
                                                  {column.type === "listref" ? <span>{column.attributes.idref}</span> : null}
                                                </div>
                                              </div>
                                              <div className='d-flex h-100'>
                                                <div className='d-flex align-items-center border-right h-100'>
                                                  <DropdownButton
                                                    className='btn-secondary row-more p-0 mr-2'
                                                    parentClass="d-flex align-items-center"
                                                    hideChevron={true}
                                                    icon="chevron-mini-down-alt"
                                                    onItemClick={e => replaceList(e, columnIndex, "answer")}
                                                    items={listItemsDropdown}
                                                  />
                                                </div>
                                                {theData.selectedItem.published ?
                                                  <div className='d-flex align-items-center justify-content-center w-40'>
                                                    <Tooltip anchorElement="target" position="top">
                                                      <button type="button" title={subquestion.elements.find(el => el.type === 'hgroup').elements[columnIndex].attributes['miext:disabled'] ? "Enable list" : "Disable list"} onClick={() => disableListItem(columnIndex, 'column')} className="p-1 btn rounded-0 delete">
                                                        <Icon type={subquestion.elements.find(el => el.type === 'hgroup').elements[columnIndex].attributes['miext:disabled'] ? "survey-list-hide" : "survey-list-show"} className='pe-none' />
                                                      </button>
                                                    </Tooltip>
                                                  </div>
                                                  :
                                                  <div className='d-flex align-items-center justify-content-center w-40'>
                                                    <Tooltip anchorElement="target" position="top">
                                                      <button type="button" title="Delete list" disabled={theData.selectedItem.published} onClick={() => onDeleteAnswerText(columnIndex, 0)} className="btn p-1 delete flex-shrink-0">
                                                        <Icon type="delete-alt" className='pe-none' />
                                                      </button>
                                                    </Tooltip>
                                                  </div>

                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  </Fragment>
                                )}
                              </Draggable>
                            ))
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              {
                subquestion.elements.find(el => el.type === 'hgroup').elements.length === 0 &&
                <div className='no-row'>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => onAddElement('h', subQuesIndex)}
                    disabled={theData.selectedItem.disabled || subquestion.disabled}>
                    Click here to add an answer...
                  </button>
                </div>
              }
            </div>
          </Fragment>
        }
      </div >
    </Fragment >
  );
}

import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Avatar } from '../../../../../shared/Avatar/Avatar';
import { Icon } from '../../../../../shared/Icon/Icon';
import ctrlAndShiftClick from '../../shared/helpers/ctrlAndShiftClick/ctrlAndShiftClick';
import { returnQuestionIcon } from '../../shared/helpers/returnQuestionIcon/returnQuestionIcon';
import { DraggableItemHierarchyItems } from './DraggableItemHierarchyItems/DraggableItemHierarchyItems';

const DraggableItem = (props) => {
  const dispatch = useDispatch()
  const { theData } = useSelector(theState => theState.setInitialDataReducer, shallowEqual);

  const count = props.data ? props.data.filter(el => el.selected === true).length : null
  const disabledItem = props.item.disabled
  const checkboxChecked = props.item.selected
  const checkboxAction = (e) => {
    e.stopPropagation()
    if (props.reportTab) {
      if (e.shiftKey) {
        props.onCheck(e, props.index, 'questions', props.item.id, true)
      } else {
        props.onCheck(e, props.index, 'questions', props.item.id)
      }
    }
    else if (props.firstColumnItem) {
      if (e.shiftKey) {
        props.onCheck(e, props.index, 'firstColumn', props.item.id, true)
      } else {
        props.onCheck(e, props.index, 'firstColumn', props.item.id)
      }
    }
    else if (props.scndColumnItem) {
      if (e.shiftKey) {
        props.onCheck(e, props.index, 'scndColumn', props.item.id, true)
      } else {
        props.onCheck(e, props.index, 'scndColumn', props.item.id)
      }
    }

    else if (props.customQues) {
      props.onCheck(props.item.id)
    }
    else if (props.question) {
      if (e.shiftKey) {
        props.onCheck(props.item.id, true)
      } else {
        props.onCheck(props.item.id)
      }
    }
  }

  const className = props.item.active ? `list-group-item list-group-item-question d-flex justify-content-between flex-grow-1 ${checkboxChecked ? "checked" : null}` : `list-group-item list-group-item-question d-flex justify-content-between flex-grow-1 ${checkboxChecked && !props.reportTab ? "checked" : ""}`;
  const questionIcon = returnQuestionIcon(props.item.type)

  let showCheckBox = null
  if (props.firstColumnItem || props.scndColumnItem || props.customQues || props.question) {
    showCheckBox =
      <span
        onClick={(e) => checkboxAction(e)}
        className={`${checkboxChecked ? 'fas text-white fa-check-square' : 'far text-muted fa-square'} fa-lg`}
      />
  }

  const onClickTaskHandler = (e) => {
    if (props.reportTab) {
      if (props.columnData !== undefined && disabledItem !== true) {
        ctrlAndShiftClick(e, props.firstColumnItem, props.onSelectQuestion, props.index, props.onCheck, props.item.id, props.scndColumnItem, props.customQues, props.question, props.rangeItems, theData, (data) => {
          dispatch({
            type: 'SET_REPORT_DISPLAY_TABLE',
            payload: props.index
          });
        })
      }
    } else {
      if (disabledItem !== true) {
        ctrlAndShiftClick(e, props.firstColumnItem, props.onSelectQuestion, props.index, props.onCheck, props.item.id, props.scndColumnItem, props.customQues, props.question, props.rangeItems, theData, (data) => {
          dispatch({
            type: 'SELECT_QUES_RANGE',
            payload: data
          });
        })
      }
    }
  }

  return (
    <Draggable
      key={props.item.id}
      draggableId={props.item.id}
      index={props.index}>

      {(provided, snapshot) => (
        <li
          data-action="click-item"
          onClick={(e) => props.isReports ? props.onSelectQuestion(props.item, props.index) : onClickTaskHandler(e)}
          className={props.firstColumnItem ? `${className} ${props.index === props.selected && !props.isReports ? 'selected ' : ''} ${disabledItem === true && checkboxChecked === false ? "bg-light font-italic disabler" : ""} ${snapshot.isDragging ? 'dragging' : ''} ` : `list-group-item list-group-item-question d-flex justify-content-between ${checkboxChecked ? "checked" : null} ${snapshot.isDragging ? 'dragging' : ''} ${disabledItem === true && checkboxChecked === false ? 'bg-light' : ""} `} ref={provided.innerRef}
          title={props.compactView ? props.item.content : null}
          {...provided.draggableProps}
          {...snapshot.isDragging}
          {...provided.dragHandleProps}>
          {(snapshot.isDragging && count !== null && count !== 0) &&
            <div className='count-overlay'>
              <Avatar
                value={count}
                color="danger"
                class="avatar avatar-count avatar-md"
              />
            </div>
          }
          <div className="d-flex align-items-center w-100 gap-md">
            <Icon type={questionIcon} className="question-icon" />
            <div className="d-flex flex-column question-text">
              <DraggableItemHierarchyItems
                hierarchy={props.item.hierarchy}
                checkboxChecked={checkboxChecked} />
              <p
                className={`question-title text-truncate ${props.minimized ? "question-title-minimized" : ""} ${props.scndColumnItem || props.firstColumnItem || props.minimized ? "d-inline-block" : ""}`}
                title={props.item.extendedTitle ? props.item.extendedTitle : props.item.content}
              >
                {props.item.extendedTitle ? props.item.extendedTitle : props.item.title}
              </p>
              {!props.compactView &&
                <span
                  style={props.reportTab ? { maxWidth: "auto" } : null}
                  className={`question-description text-truncate ${props.minimized ? "question-description-minimized" : ""} ${props.scndColumnItem || props.firstColumnItem || props.minimized ? "d-inline-block" : ""}`}
                  title={props.item.content}>
                  {props.item.content}
                </span>
              }
            </div>
          </div>
          <div className="d-flex align-items-center q-checkbox">
            {
              props.item.options && Object.keys(props.item.options).length !== 0 ?
                <i className="fas fa-sliders-h pr-3" /> : null
            }
            {showCheckBox}
          </div>
        </li>
      )}
    </Draggable >
  );
}
export default DraggableItem;

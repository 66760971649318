import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, useParams } from 'react-router';

import { SignUpCard } from './SignUpCard/SignUpCard';
import { fetchPost as registerNewUser } from '../../services/services';

interface SignUpFormData {
  firstName: string
  lastName: string
  password: string
  confirmPassword: string
}

export const SignUp = () => {
  document.title = "Register – Walr";
  const { user, loginWithRedirect } = useAuth0()
  const params: { key: string, registerType: string } = useParams()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [formData, setFormData] = useState<SignUpFormData>({ firstName: '', lastName: '', password: '', confirmPassword: '' })

  const onChangeHandler = (e: TODO) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const onSubmitHandler = async (e: TODO) => {
    e.preventDefault()
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage('The two passwords you entered are not matching.')
    } else {
      if (params.registerType && params.key) {
        setIsLoading(true)
        const body = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          password: formData.password,
          key: params.key
        }
        if (params.registerType === "u") {
          const response: TODO = await registerNewUser("customer/register-user", '', body)
          if (response) {
            if (response.status === 201) {
              loginWithRedirect()
            } else {
              const responseJson = await response.json()
              setIsLoading(false)
              setErrorMessage(responseJson.error ? responseJson.error : 'Something went wrong, please try again!')
            }
          }
        }
      }
    }
  }

  if (user) {
    return (
      <Redirect to="/" />
    )
  }
  return (
    <div className="d-flex flex-column vh-100 w-100">
      <div className="container flex-grow-1">
        <div className="row justify-content-center h-100">
          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="login-card border shadow-lg w-100">
              <SignUpCard
                isLoading={isLoading}
                errorMessage={errorMessage}
                onSubmitHandler={onSubmitHandler}
                onChangeHandler={onChangeHandler} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

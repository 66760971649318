export const returnSupportedTypesValue = (data: string[]) => {
  const updatedData: { text: string, id: string }[] = []

  data.forEach((item: string) => {
    switch (item) {
      case 'f':
        updatedData.push({ text: 'Numeric (F)', id: item });
        break;
      case 'h':
        updatedData.push({ text: 'Hour (H)', id: item });
        break;
      case 'm':
        updatedData.push({ text: 'Multiple (M)', id: item });
        break;
      case 'n':
        updatedData.push({ text: 'Single (N)', id: item });
        break;
      case 'open':
        updatedData.push({ text: 'Open', id: item });
        break;
      case 'quota':
        updatedData.push({ text: 'Quota', id: item });
        break;
      case 'rm':
        updatedData.push({ text: 'Multiple grid (Rm)', id: item });
        break;
      case 'rn':
        updatedData.push({ text: 'Single grid (Rn)', id: item });
        break;
      default:
        break;
    }
  })

  return updatedData
}
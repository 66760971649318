import { Icon } from '../Icon/Icon';

interface Props { error: TODO, resetErrorBoundary: TODO }

export const FallbackUI = ({ error, resetErrorBoundary }: Props) => {
  const FreshworksWidget = window.FreshworksWidget
  if (FreshworksWidget) {
    FreshworksWidget('hide', 'launcher');
  }

  return (
    <div className='fallback-ui d-flex align-items-center justify-content-center bg-white'>
      <Icon className='walr-logo' type="walr-logo" />
      <div className="fallback-card">
        <h1 className='mb-3 stronger'>Something went wrong.</h1>
        <p className='fallback-message mb-3'>If you'd like to help you can describe what happened by contacting us.</p>
        <p title={error.message} className='fallback-message mb-4'>Error: {error.message}</p>
        <div className="d-flex align-items-center">
          <button type='button' className='btn btn-primary mr-2' onClick={resetErrorBoundary}>Reload</button>
          <button type='button' className='btn btn-secondary' onClick={() => FreshworksWidget('open', 'ticketForm')}>Tell us what happened</button>
        </div>
      </div>
    </div>
  )
}

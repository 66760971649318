import React from 'react';
import { returnMultigridVariablePropertyName } from '../../helpers/importDataHelpers/returnMultigridVariablePropertyName/returnMultigridVariablePropertyName';
import CustomInput from '../../../../../../shared/CustomInput/CustomInput';

export default ({ el, index, onChangeExcluded, onChangeMultiGridTextHandler }) => {
  const variablePropertyName = returnMultigridVariablePropertyName(el)

  if (el.multigrid && variablePropertyName) {
    const cellTypes = ['id', 'text']
    return (
      <tbody id="multigrid-table-body">
        <tr>
          <td rowSpan="3" colSpan="2" />
          {
            el[variablePropertyName].length > 0 ?
              el[variablePropertyName][0].values?.value ?
                el[variablePropertyName][0].values.value.map((el, id) => (
                  <td key={id}>
                    <input
                      onChange={(e) => onChangeExcluded(e, index, 'multiGridCol', variablePropertyName, id)}
                      type="checkbox"
                      className="k-checkbox"
                      checked={el['@miextsss:excluded'] === 'true' ? false : true}
                    />
                  </td>
                ))
                :
                el[variablePropertyName][0].variable.map((el, id) => (
                  <td key={id}>
                    <input
                      onChange={(e) => onChangeExcluded(e, index, 'multiGridCol', variablePropertyName, id)}
                      type="checkbox"
                      className="k-checkbox"
                      checked={el['@miextsss:excluded'] === 'true' ? false : true}
                    />
                  </td>
                ))
              :
              <td />
          }
        </tr>
        {
          cellTypes.map((cellType, key) => (
            <tr key={key}>
              {
                el[variablePropertyName].length > 0 ?
                  el[variablePropertyName][0].values?.value ?
                    el[variablePropertyName][0].values.value.map((el, id) => (
                      <td key={id} className={cellType !== 'id' ? 'p-0' : ''}>
                        {
                          cellType === 'id' ?
                            id + 1
                            :
                            <div className="form-group m-0">
                              <CustomInput
                                type="text"
                                className='form-control text-center'
                                value={el.text}
                                onChange={(value) => onChangeMultiGridTextHandler(value, index, 0, variablePropertyName, id)}
                              />
                            </div>
                        }
                      </td>
                    ))
                    :
                    el[variablePropertyName][0].variable.map((el, id) => (
                      <td key={id}>
                        {
                          cellType === 'id' ?
                            id + 1
                            :
                            <div className="form-group m-0">
                              <CustomInput
                                type="text"
                                className='form-control text-center'
                                value={Array.isArray(el.values.value) ? el.values.value.find(el => !el['@miextsss:excluded'] || el['@miextsss:excluded'] === 'false').text : el.values.value.text}
                                onChange={(value) => onChangeMultiGridTextHandler(value, index, 0, variablePropertyName, id)}
                              />
                            </div>
                        }
                      </td>
                    ))
                  :
                  <td />
              }
            </tr>
          ))
        }
        {
          el[variablePropertyName].map((val, id) => (
            <tr key={id}>
              <td>
                <input
                  onChange={(e) => onChangeExcluded(e, index, 'multiGridRow', variablePropertyName, id)}
                  type="checkbox"
                  className="k-checkbox"
                  checked={val['@miextsss:excluded'] === 'true' ? false : true}
                />
              </td>
              <td className='p-0'>
                <div className="form-group m-0">
                  <CustomInput
                    type="text"
                    className='form-control'
                    value={val.label?.text ? val.label.text : val["miextsss:label"]?.text ? val["miextsss:label"].text : ''}
                    onChange={(value) => onChangeMultiGridTextHandler(value, index, id, variablePropertyName)}
                  />
                </div>
              </td>
              {
                el[variablePropertyName][0].values?.value ?
                  el[variablePropertyName][0].values.value.map((val, id) => (
                    <td key={id} />
                  ))
                  :
                  el[variablePropertyName][0].variable.map((val, id) => (
                    <td key={id} />
                  ))
              }
            </tr>
          ))
        }
      </tbody>
    );
  }
  return null
}

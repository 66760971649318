import React from 'react';
import CustomInput from '../../../../../../shared/CustomInput/CustomInput';

export default ({ el, onChangeHandler, index, onChangeExcluded }) => {
  const valuesProperty = el.values ? 'values' : 'variable'
  if (el.values) {
    if (el.values.value) {
      if (Array.isArray(el.values.value)) {
        return (
          <tbody id="default-table-body">
            {
              el.values.value.map((val, id) => (
                <tr key={id}>
                  <td>
                    <input
                      onChange={(e) => onChangeExcluded(e, index, 'default-array', valuesProperty, id)}
                      type="checkbox"
                      className="k-checkbox"
                      checked={val['@miextsss:excluded'] === 'true' ? false : true}
                    />
                  </td>
                  <td>{val['@code']}</td>
                  <td>
                    <div className="form-group m-0">
                      <label className="sr-only" />
                      <CustomInput
                        onChange={(value) => onChangeHandler(value, index, 'questionValue', 'title', id, valuesProperty, 'default-array')}
                        type="text"
                        className="form-control text-center"
                        value={val.text['#text'] ? val.text['#text'] : val.text}
                      />
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        );
      }
      return (
        <tbody id="default-table-body">
          <tr>
            <td>
              <input
                onChange={(e) => onChangeExcluded(e, index, 'default-array', valuesProperty, 0)}
                type="checkbox"
                className="k-checkbox"
                checked={el.values.value['@miextsss:excluded'] === 'true' ? false : true}
              />
            </td>
            <td>{el.values.value['@code']}</td>
            <td>
              <div className="form-group m-0">
                <label className="sr-only" />
                <CustomInput
                  onChange={(value) => onChangeHandler(value, index, 'questionValue', 'title', 0, valuesProperty, 'default-array')}
                  type="text"
                  className="form-control text-center"
                  value={el.values.value.text['#text'] ? el.values.value.text['#text'] : el.values.value.text}
                />
              </div>
            </td>
          </tr>
        </tbody>
      );
    } if (!el.values.value && el.title) {
      return (
        <tbody>
          {
            <tr>
              <td colSpan={2}>{1}</td>
              <td>
                <div className="form-group m-0">
                  <label className="sr-only" />
                  <CustomInput
                    onChange={(value) => onChangeHandler(value, index, 'questionValue', 'title', 0, valuesProperty, 'default-obj')}
                    type="text"
                    className="form-control text-center"
                    value={el.title}
                  />
                </div>
              </td>
            </tr>
          }
        </tbody>
      )
    }
    return null
  }
  return (
    <tbody>
      {
        <tr>
          <td>
            <input
              onChange={(e) => onChangeExcluded(e, index, 'default-obj', valuesProperty, 0)}
              type="checkbox"
              className="k-checkbox"
              checked={el['@miextsss:excluded'] === 'true' ? false : true}
            />
          </td>
          <td>{1}</td>
          <td>
            <div className="form-group m-0">
              <label className="sr-only" />
              <CustomInput
                onChange={(value) => onChangeHandler(value, index, 'questionValue', 'title', 0, valuesProperty, 'default-obj')}
                type="text"
                className="form-control text-center"
                value={el.title}
              />
            </div>
          </td>
        </tr>
      }
    </tbody>
  )
}

export const returnNumberOfSelectedSubAccountUsers = (users: any, subAccountId: string, shareDetails: any) => {
  let numberOfSelected = 0

  if (shareDetails?.shareUsers.length > 0) {
    const sharedSubAccountUsers = shareDetails.shareUsers.filter((user: any) => user.isSubAccountUser)
    sharedSubAccountUsers.forEach((subUser: any) => {
      if (subUser.subAccountId === subAccountId) {
        numberOfSelected++
      }
    })
    if (users) {
      const selected = users.filter((user: any) => user.selected)
      if (selected.length > 0) {
        selected.forEach((selectedUser: any) => {
          if (!sharedSubAccountUsers.find((item: any) => item.email === selectedUser.email)) {
            numberOfSelected++
          }
        })
      }
    }
  } else {
    if (users) {
      const selected = users.filter((user: any) => user.selected)
      if (selected.length > 0) {
        numberOfSelected = numberOfSelected + selected.length
      }
    }
  }

  return numberOfSelected
}
import React, { useState, useEffect, type FormEvent, type ReactElement } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import type { ListItemProps } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'

import { BaseMultiSelect } from '../../../../shared/Inputs';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import type { AdminUser } from '../../../../../interfaces/userInterface/userInterface';
import type { Group } from '../../../../../interfaces/adminInterfaces/adminInterfaces';
import { returnUserDisplayName } from '../utils/returnUserDisplayName/returnUserDisplayName';
import { fetchPutResOrJson as updateGroup, fetchGetJson as getUsers } from '../../../../../services/services';

type Props = {
  group?: Group | null;
  handleClose: () => void;
  updateGroups: () => void;
}

export const EditGroupModal = ({ handleClose, group, updateGroups }: Props) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [formData, setFormData] = useState<{ name?: string, description?: string, userIds?: string[] }>({ name: group?.name, description: group?.description, userIds: group?.userIds })
  const [users, setUsers] = useState<AdminUser[]>([])
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getUsers('users', token)
      .then((res: TODO) => {
        if (res && (res.message || res.error)) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.message ? res.message : res.error } })
        } else {
          setUsers(res.map((user: AdminUser) => {
            return {
              ...user,
              displayName: returnUserDisplayName(user)
            }
          }))
        }
      })
  }, [dispatch, token])

  const onChangeHandler = (e: TODO) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.name === "userIds" ? e.target.value?.map((el: AdminUser) => el.id) : e.target.value,
    })
  }

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    const body = {
      name: formData.name,
      description: formData.description,
      userIds: formData.userIds
    }
    if (group?.id) {
      setIsLoading(true);
      updateGroup(`groups/${group.id}`, token, body)
        .then((res: TODO) => {
          setIsLoading(false);
          if (res && res.status === 200) {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The group has been updated successfully' } })
            handleClose()
            updateGroups()
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    }
  }

  const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = itemProps.dataItem?.displayName;
    return React.cloneElement(li, li.props, itemChildren);
  }

  return (
    <Dialog title="Edit group"
      onClose={handleClose}
      className="d-flex flex-column"
      width="40%">
      <div className="d-flex flex-column p-4 gap-md">
        <p className="m-0">Name</p>
        <Input
          required
          onChange={onChangeHandler}
          value={formData.name}
          placeholder="Enter name"
          name="name"
          className="w-100"
        />

        <p className="m-0">Description</p>
        <Input
          onChange={onChangeHandler}
          value={formData.description}
          placeholder="Enter description"
          name="description"
          className="w-100"
        />

        <p className="m-0">Add users</p>
        <BaseMultiSelect
          data={users}
          itemRender={itemRender}
          className="w-100"
          textField="displayName"
          dataItemKey="id"
          onChange={onChangeHandler}
          name="userIds"
          value={users.filter(el => formData.userIds?.includes(el.id))}
          placeholder="Select users to add"
        />
      </div>
      {
        errorMessage &&
        <div className="mx-4">
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage}
          />
        </div>
      }
      <DialogActionsBar>
        <button type='button' className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button disabled={isLoading} type='button' className="k-button btn btn-primary" onClick={onSubmitHandler}>Update</button>
      </DialogActionsBar>
    </Dialog>
  )
}

// ID is both null and undefined because it's possible for it to enter undefined and leave null
export default (event: {id: string | null | undefined, name: string}[]) => {
  const values = event;
  const lastItem = values[values.length - 1];
  if (lastItem) {
    values.pop();
    const sameItem = values.find((value: { name: string }) => value.name === lastItem.name);
    if (sameItem === undefined) {
      if (lastItem.id) {
        values.push(lastItem);
      } else {
        values.push({ ...lastItem, id: null })
      }
    }
  }
  return values;
}

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { surveyTools } from '../../toolTabs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { PastePad } from '../../PastePad/PastePad';
import { SurveyScript } from '../../SurveyScript/SurveyScript';
import { SurveyCSS } from '../../SurveyCSS/SurveyCSS';
import { QuotaModal } from '../../helpers/QuotaModal/QuotaModal';
import { SurveyDesignTabs } from '../../SurveyDesignTabs/SurveyDesignTabs';
import { ElementActions } from '../../helpers/ElementActions/ElementActions';
import { SectionElements } from '../../helpers/SectionElements/SectionElements';
import { SurveyFilterBuilder } from '../../SurveyFilterBuilder/SurveyFilterBuilder';
import { AddNewElementModal } from '../../helpers/AddNewElementModal/AddNewElementModal';
import { ErrorLog } from '../../helpers/ErrorLog/ErrorLog';
import GenericLibrary from '../../helpers/LibraryModal/GenericLibrary';
import PasteQuestionsModal from '../../helpers/PasteQuestionsModal/PasteQuestionsModal';
import { InProgressOverlay } from '../../../../../shared/InProgressOverlay/InProgressOverlay';
import { SectionOptionsDialog } from '../../helpers/SectionOptionsDialog/SectionOptionsDialog';
import ConfirmActionModal from '../../../../../shared/ConfirmActionModal/ConfirmActionModal';
import { ValidationErrorMessage } from '../../helpers/ValidationErrorMesage/ValidationErrorMessage';
import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import usePrevious from '../../../../../shared/customHooks/usePrevious';
import { Icon } from '../../../../../shared/Icon/Icon';
import { DropdownButton } from '../../../../../shared/DropdownButton/DropdownButton';
import { NewSurveyOptions } from '../../NewSurveyOptions/NewSurveyOptions';
import { SurveyDesignListNameModal } from './SurveyDesignListNameModal/SurveyDesignListNameModal';
import { SurveyDesignListModalView } from './SurveyDesignListModalView/SurveyDesignListModalView';
import { SurveyDesignListColumn } from './SurveyDesignListColumn/SurveyDesignListColumn';
import { updateLanguagesList } from '../../../../../shared/LanguagesList/updateLanguagesList';
import LanguagesList from '../../../../../shared/LanguagesList/LanguagesList';
import { useDebounce } from '../../../../../shared/customHooks/useDebounce';
import { SurveyDesignSectionCSS } from './SurveyDesignSectionCSS/SurveyDesignSectionCSS';
import { useKeyboardShortcut } from '../../../../../shared/customHooks/useKeyboardShortcut';
import { returnValidJson } from '../../../../../Workflow/WorkflowTabContent/WorkflowDesignTabContent/helpers/returnValidJson/returnValidJson';
import {
  returnElementOptions,
  returnOnDeleteElement,
  returnSurveyDesignData,
  returnValidationErrors,
  returnSurveySearchData,
  returnSelectedElementData,
  returnOnReorderSectionData,
  returnOnReorderQuestionData,
  returnSurveyElementTextChange,
  returnDuplicateBodyData,
  returnOnAddElementData
} from '../../helpers/returnDataHelpers/returnDataHelpers';
import {
  fetchGetJson as getSurveyQuota,
  fetchGetJson as validateSurvey,
  fetchPostJson as addQuotaElement,
  fetchGetJson as getSurveyElement,
  fetchPatch as updateQuotaCells,
  fetchPutJson as updateSurveyElement,
  fetchPostJson as createSurveyElement,
  fetchPutJson as updateSurveyIndex,
  fetchPutResOrJson as updateSurveyIndexFromAdd,
  fetchGetJson as getSurveyReviews,
  fetchPost,
  fetchPostJson,
  fetchPutResOrJson,
  fetchPutJson,
  rsAppUrl
} from '../../../../../../services/services';

const tabs = [
  { title: 'Edit', icon: 'fas fa-pen fa-sm', id: 'edit' },
  /*{ title: 'Options', icon: 'fas fa-cog fa-sm', id: 'options' }*/
  { title: 'Logic', icon: 'fas fa-tasks fa-sm', id: 'logic' },
  { title: 'Preview', icon: 'fa fa-eye fa-sm', id: 'preview' },
];

export const SurveyDesignEditorTool = ({ updateElements, toolSelected, setToolSelected }) => {
  const dispatch = useDispatch()
  const { user } = useAuth0()
  const { survey } = useParams();
  const [quotaModal, setQuotaModal] = useState(false)
  const [pasteModal, setPasteModal] = useState(false)
  const [searchError, setSearchError] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [removeErrorBadge, setRemoveErrorBadge] = useState(false)
  const [showModal, setShowModal] = useState({ show: false, section: false, index: 0 })
  const [deleteSectionModal, setDeleteSectionModal] = useState({ show: false, id: null })
  const [optionsDialog, setOptionsDialog] = useState({ show: false, item: null, optionValues: {}, id: null, combineFilterData: [] })
  const [errorMessage, setErrorMessage] = useState("");
  const [quotaError, setQuotaError] = useState("");
  const [libraryModal, setLibraryModal] = useState(false);
  const [openImportFromGlobalLibrary, setOpenImportFromGlobalLibrary] = useState(false);
  const [listNameModal, setListNameModal] = useState({ show: false, id: null });
  const [listNameInputValue, setListNameInputValue] = useState("");
  const [sectionIndex, setSectionIndex] = useState(0)

  const { token } = useSelector((state) => state.tokenStateReducer);
  const { data } = useSelector(theState => (theState.breadcrumbStateReducer));
  const { theData, selectedElement, showErrorLog } = useSelector(theState => (theState.surveyInitialDataReducer))
  const prevToolSelected = usePrevious(toolSelected)
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_INDEX' }), 1000);
  const itemsToRenderInDropdownEditorButton = theData.validationErrors.length > 0 ? [{ text: 'Copy elements' }, { text: 'Paste elements' }, { text: `${theData.toggleDisabledQuestions ? "Show disabled questions" : "Hide disabled questions"}` }, { text: `${theData.showElementLogic ? "Hide logic" : "Show logic"}` }, { text: 'separator' }, { text: 'Duplicate' }, { text: 'Paste pad' }, { text: 'Error log' }] : [{ text: 'Copy elements' }, { text: 'Paste elements' }, { text: `${theData.toggleDisabledQuestions ? "Show disabled questions" : "Hide disabled questions"}` }, { text: `${theData.showElementLogic ? "Hide logic" : "Show logic"}` }, { text: 'separator' }, { text: 'Duplicate' }, { text: 'Paste pad' }, { text: 'separator' }, { text: 'Refresh survey comments' }]
  const customerId = user[`${rsAppUrl}/customerid`].split(',');
  const showSectionNameErrorWithDebounce = useDebounce(() => dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Section names can only contain characters a-z, A-Z, 0-9, _ and start with a-z, A-Z" } }), 500);

  const tools = surveyTools;

  const handleChangeForSectionNames = (e, sectionIndex) => {
    const updatedOriginalData = JSON.parse(JSON.stringify(theData.originalData));
    if (e.target.value.match(/^(?=[a-zA-Z])[a-zA-Z0-9_]*$/) !== null || e.target.value === "") {
      updatedOriginalData.elements[sectionIndex].attributes.id = e.target.value;
      const data = returnSurveyDesignData(updatedOriginalData);
      dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: updatedOriginalData } });
      saveWithDebounce();
    } else {
      showSectionNameErrorWithDebounce();
    }
  }

  const onDragEnd = (result) => {
    let reorderedData = null;
    if (result.destination) {
      if (result.type === 'sections') {
        reorderedData = returnOnReorderSectionData(result.source.index, result.destination.index, theData);
      } else if (result.type === 'elements') {
        reorderedData = returnOnReorderQuestionData(
          result.source.index,
          result.destination.index,
          result.source.droppableId,
          result.destination.droppableId,
          theData
        );
      }
      if (result.source.droppableId !== result.destination.droppableId) {
        dispatch({ type: 'SURVEY_RELOAD_OPTIONS', payload: { reload: false, expression: null } });
      }
      const body = reorderedData.originalData;

      fetchPutJson(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, body)
        .then((res) => {
          if (res && !res.error && !res.message) {
            const originalData = JSON.parse(JSON.stringify(res))
            originalData.languages.forEach(lang => { // Add language info, a compensation for backend
              const langInfo = LanguagesList.find(el => el.code === lang.code);
              if (langInfo) { lang.name = langInfo.name; lang.native = langInfo.native; }
            });
            const data = returnSurveyDesignData(res, result.draggableId);
            dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } });
            if (theData.validationErrors.length > 0) {
              validateSurvey(`su/projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}/validate`, token).then((res) => {
                if (res?.error) {
                  dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } });
                } else if (res) {
                  let errors = [];
                  if (res.isValid === false) {
                    if (res.errors.length > 0) {
                      errors = returnValidationErrors(res);
                    } else {
                      errors = theData.validationErrors;
                    }
                  }
                  dispatch({
                    type: 'UPDATE_SURVEY_VALIDATION_ERRORS',
                    payload: {
                      errors: errors,
                      originalItem: theData.originalSelectedItem ? theData.originalSelectedItem : null,
                      selectedItem: theData.selectedItem ? theData.selectedItem : null,
                      data: returnSurveyDesignData(originalData, theData.selectedItem ? theData.selectedItem.id : null, errors),
                    },
                  });
                  dispatch({ type: 'TOGGLE_DISABLED_QUESTIONS', payload: false });
                  setRemoveErrorBadge(false);
                }
              });
            }
          }
        });
    }
  };

  const findSelectedQuestionReview = (selectedItem) => {
    return !theData.surveyReviews || theData.surveyReviews.length ? theData.surveyReviews.find((element) => element.questionId === selectedItem.id) : null;
  }

  const onSelectSurveyElement = (res, item, data, validate) => {
    if (validate) {
      validateSurvey(`su/projects/${theData.originalSelectedItem.projectId}/surveys/${theData.originalSelectedItem.surveyId}/validate`, token).then(
        (res) => {
          if (res?.error) {
            dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } });
          } else if (res) {
            let errors = [];
            if (res.isValid === false) {
              if (res.errors.length > 0) {
                errors = returnValidationErrors(res);
              } else {
                errors = theData.validationErrors;
              }
            }
            dispatch({
              type: 'UPDATE_SURVEY_VALIDATION_ERRORS',
              payload: {
                errors: errors,
                originalItem: res,
                selectedItem: theData.selectedItem
                  ? returnSelectedElementData(
                    res,
                    theData.data.find((el) => el.index === theData.selectedItem.section).elements.find((el) => el.selected === true),
                    errors,
                    theData.data
                  )
                  : null,
                data: returnSurveyDesignData(theData.originalData, theData.selectedItem ? theData.selectedItem.id : null, errors),
              },
            });
            dispatch({ type: 'TOGGLE_DISABLED_QUESTIONS', payload: false });
          }
        }
      );
    } else {
      const selectedItem = returnSelectedElementData(res, item, theData.validationErrors, theData.data);
      selectedItem._etag = res._etag
      const elementOptions =
        res.type !== 'quota-document'
          ? [
            {
              type: res.type,
              expanded: true,
              published: res.attributes?.['miext:id'] ? true : false,
              isDisabled: res.attributes?.['miext:disabled'] && res.attributes['miext:disabled'] === 'true' ? true : false,
              index: null,
              items: returnElementOptions(
                res,
                res.attributes?.['miext:disabled'] && res.attributes['miext:disabled'] === 'true' ? true : false,
                res.type,
                null
              ),
            },
          ]
          : null;

      const updatedSelectedItem = selectedItem.type === 'ques' ? selectedItem.elements.filter(el => el.type === 'subq')[0] : selectedItem;

      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: updatedSelectedItem })
      dispatch({ type: 'SURVEY_DATA_SELECT_ELEMENT', payload: { data: data, item: selectedItem, originalItem: res, elementOptions: elementOptions, selectedItemReview: findSelectedQuestionReview(selectedItem) }, });
    }
  };

  const returnSelectedElementRes = (item, data, validate) => {
    if (item.type !== 'quota') {
      getSurveyElement(`su/projects/${item.projectId}/surveys/${item.surveyId}/elements/${item.id}`, token).then((res) => {
        onSelectSurveyElement(res, item, data, validate);
      });
    } else if (item.type === 'quota') {
      getSurveyQuota(`projects/${item.projectId}/surveys/${item.surveyId}/quotas/${item.quotaId}/extended`, token).then((res) => {
        if (res) {
          if (res.error || res.message) {
            setErrorMessage(res.error ? res.error : res.message);
          } else {
            const originalQuota = JSON.parse(JSON.stringify(res.quotaDocument));
            originalQuota.quotaCells = res.cells
            originalQuota.disabled = res.disabled;
            originalQuota.published = res.published;
            originalQuota.quotaTable = res.quotaTable;
            onSelectSurveyElement(originalQuota, item, data, validate);
          }
        }
      });
    }
  };

  const scrollElementIntoView = (item) => {
    if (item.selected) {
      const selected = document.getElementById(item.id)
      selected?.scrollIntoView({ behavior: "smooth" })
    } else if (typeof item === 'string') {
      const selected = document.getElementById(item)
      selected?.scrollIntoView({ behavior: "smooth" })
    }
  };

  const findItemAndSetAsClicked = (clicked) => {
    if (clicked.location) {
      if (clicked.location.length === 1) {
        scrollElementIntoView(clicked.location[0].index)
        dispatch({ type: "SET_SHOW_ERROR_LOG", payload: false })
      } else if (clicked.location[0].type === "prolog") {
        if (clicked.description.includes('ID') || clicked.description.includes("is not valid")) {
          const list = clicked.location.find(el => el.type === "list")
          const foundList = { ...theData.originalData.lists[list.index - 1] }
          setListNameModal({ show: true, id: foundList.attributes.id })
          setListNameInputValue(foundList.attributes.id)
          dispatch({ type: "SET_SHOW_ERROR_LOG", payload: false })
        } else {
          dispatch({ type: "SET_SHOW_ERROR_LOG", payload: false })
        }
      } else {
        const section = clicked.location.find(el => el.type === "section").index - 1
        const typeOfElement = theData.data[section].elements.filter(el => el.type === clicked.location[1].type)
        const questionIndex = clicked.location[1].index - 1
        const found = typeOfElement[questionIndex]
        if (theData.selectedItem?.id !== found.id) {
          setQuestionSelected(found)
        }
        dispatch({ type: "SET_SHOW_ERROR_LOG", payload: false })
      }
    }
  };

  const setQuestionSelected = (item) => {
    if (theData.selectedItem?.type === 'quotastop') {
      dispatch({ type: 'SURVEY_RELOAD_OPTIONS', payload: { reload: false, expression: null } });
    }
    if (theData.hasChanges && theData.selectedItem) {
      onSaveChanges('nextElement', item.id);
    } else if (theData.editingElement && theData.lastEditingContent) {
      if (theData.editingElement.type !== 'quota' && theData.editingElement.content !== theData.lastEditingContent) {
        const originalItem = returnSurveyElementTextChange(
          theData.originalSelectedItem,
          theData.editingElement,
          theData.lastEditingContent,
          theData.editingLanguage,
          theData.originalData.languages.map((el) => el.code)
        );
        const selectedItem = returnSelectedElementData(
          originalItem,
          theData.data.find((el) => el.index === theData.selectedItem.section).elements.find((el) => el.selected === true),
          theData.validationErrors,
          theData.data
        );
        const elementOptions = [
          {
            type: originalItem.type,
            expanded: true,
            published: originalItem.attributes?.['miext:id'] ? true : false,
            isDisabled:
              originalItem.attributes?.['miext:disabled'] && originalItem.attributes['miext:disabled'] === 'true'
                ? true
                : false,
            index: null,
            items: returnElementOptions(
              originalItem,
              originalItem.attributes?.['miext:disabled'] && originalItem.attributes['miext:disabled'] === 'true'
                ? true
                : false,
              originalItem.type,
              null
            ),
          },
        ];
        dispatch({ type: 'SET_SELECTED_ELEMENT', payload: selectedItem })
        dispatch({ type: 'UPDATE_SURVEY_ELEMENT_DATA', payload: { item: selectedItem, originalItem: originalItem, elementOptions: elementOptions } });
      }
      if (theData.editingElement.type === 'quota' && theData.editingElement.value !== theData.lastEditingContent) {
        const originalItem = theData.originalSelectedItem;
        originalItem.quotaCells.find((item) => item.id === theData.editingElement.id).targetValue = theData.lastEditingContent;
        const selectedItem = returnSelectedElementData(
          originalItem,
          theData.data.find((el) => el.index === theData.selectedItem.section).elements.find((el) => el.selected === true),
          theData.validationErrors,
          theData.data
        );
        dispatch({ type: 'SET_SELECTED_ELEMENT', payload: selectedItem })
        dispatch({ type: 'UPDATE_SURVEY_ELEMENT_DATA', payload: { item: selectedItem, originalItem: originalItem, elementOptions: null } });
      }
      onSaveChanges('nextElement', item.id);
    }
    if (!theData.hasChanges) {
      dispatch({ type: 'LOAD_SURVEY_ELEMENT_DATA' });
      const data = JSON.parse(JSON.stringify(theData.data));
      let value = false;
      data.forEach((section) =>
        section.elements.forEach((el) => {
          if (el.id === item.id) {
            el.selected = !el.selected;
            value = el.selected;
          } else {
            el.selected = false;
          }
        })
      );
      if (value) {
        returnSelectedElementRes(item, data);
      } else {
        dispatch({ type: 'SURVEY_DATA_SELECT_ELEMENT', payload: { data: data, item: null, originalItem: null, elementOptions: null } });
      }
    }
  };

  const onSearchData = (e) => {
    if (e.target.value) {
      const updatedData = returnSurveySearchData(e, theData);
      dispatch({ type: 'SEARCH_SURVEY_DATA', payload: updatedData });
      if (updatedData.length === 0) {
        setSearchError(true);
      } else {
        setSearchError(false);
      }
    } else {
      dispatch({ type: 'SEARCH_SURVEY_DATA', payload: theData.oldData });
    }
  };

  const addSurveyElement = (id, name, type, quotaBody, isCustomQuestion) => {
    if (id !== 'DefaultSection') {
      dispatch({ type: 'LOAD_SURVEY_ELEMENT_DATA' });
    }
    let selectedElement = null
    theData.data.forEach(section => {
      section.elements.forEach(el => {
        if (el.selected) {
          selectedElement = el
        }
      })
    })

    if (id !== 'Quota' && type !== 'ques') {
      const body = { templateId: `${id}`, languages: ['en'], isCustomQuestion, positionUnder: `section-${sectionIndex}` };
      if (selectedElement) {
        body.positionUnder = selectedElement.id
      }
      createSurveyElement(`su/projects/${data.projectId}/surveys/${data.documentId}`, token, body)
        .then((res) => {
          if (res && !res.error && !res.message) {
            const originalData = res.index
            originalData.languages = updateLanguagesList(originalData.languages)
            const data = returnSurveyDesignData(res.index, res.element.id, theData.validationErrors)
            dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } })
            data.every(section => {
              return section.elements.every(element => {
                if (element.selected) {
                  onSelectSurveyElement(res.element, element, data, false)
                  return false
                } return true
              });
            })
            dispatch({ type: 'FORCE_STOP_LOADING' })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `${name} has been added` } });
            scrollElementIntoView({ ...res.element, selected: true })
          }
        });
    } else if (id === 'Quota') {
      const body = quotaBody;
      addQuotaElement(`projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}/quotas`, token, body)
        .then((res) => {
          if (res.error || res.message) {
            setQuotaError(res.error ? res.error : res.message);
          } else {
            const newElement = res.indexElement;
            const newIndex = returnOnAddElementData(theData.originalData, theData.selectedItem, newElement, showModal, sectionIndex);
            finalizeAddSurveyElement(newElement, name, newIndex);
            setQuotaError('');
            setQuotaModal(false);
          }
        });
    } else if (type === 'ques') {
      const body = {
        templateId: `${id}`,
        isLibraryItem: true
      };
      createSurveyElement(`su/projects/${data.projectId}/surveys/${data.documentId}`, token, body)
        .then((res) => {
          if (res && !res.error && !res.message) {
            finalizeAddSurveyElement(res.element, name, res.index);
          }
        });
    }
  };

  const finalizeAddSurveyElement = (newElement, name, newIndex, type) => {
    const body = newIndex;
    if (newElement.type === "quota") {
      fetchPutResOrJson(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, body).then((res) => {
        if (res.status === 200) {
          updateElements(newElement.type !== "quota" ? newElement.id : newElement.referenceQuotaId);
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `${name} has been added` } });
        } else if (res.status === 412) {
          setErrorMessage('Another user has updated the survey in the meantime. Click "Try again" to refresh the page.');
        }
      });
    } else {
      updateSurveyIndexFromAdd(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, body).then((res) => {
        if (res.status === 200) {
          if (newElement.type !== 'section') {
            if (type !== 'pastePad') {
              updateElements(newElement.type !== "quota" ? newElement.id : newElement.referenceQuotaId);
            } else {
              addElementFromPastePad(newElement.id);
            }
          } else {
            updateElements();
          }
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `${name} has been added` } });
        } else if (res.status === 412) {
          setErrorMessage('Another user has updated the survey in the meantime. Click "Try again" to refresh the page.');
        }
      });
    }
  };

  const addElementFromPastePad = (id) => {
    getSurveyElement(`su/projects/${data.projectId}/surveys/${data.documentId}/elements/${id}`, token).then((res) => {
      const originalItem = res;
      originalItem.elements.find((el) => el.type === 'rgroup').elements = [];
      if (
        originalItem.elements.find((el) => el.type === 'subq').attributes['type'] === 'rm' ||
        originalItem.elements.find((el) => el.type === 'subq').attributes['type'] === 'rn'
      ) {
        originalItem.elements.find((el) => el.type === 'subq').elements.find((el) => el.type === 'hgroup').elements = [];
      }
      const body = originalItem;
      updateSurveyElement(`su/projects/${data.projectId}/surveys/${data.documentId}/elements/${id}`, token, body).then((res) => {
        if (res.indexElement && res.definition) {
          updateElements(id);
        }
      });
    });
  };

  const deleteSurveyElement = (body) => {
    updateSurveyIndex(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, body)
      .then((res) => {
        if (res.error || res.message) {
          dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } });
        } else {
          const originalData = res;
          originalData.languages = updateLanguagesList(originalData.languages)
          const data = returnSurveyDesignData(res)
          setDeleteSectionModal({ show: false, id: null });
          dispatch({ type: 'UPDATE_ORIGINAL_DATA', payload: res });
          dispatch({ type: 'SURVEY_DATA_SELECT_ELEMENT', payload: { data: data, item: null, originalItem: null, elementOptions: null } });
        }
      });
  };

  const onDeleteElement = () => {
    if (
      selectedElement.type !== 'info' &&
      selectedElement.type !== 'ques' &&
      selectedElement.type !== 'goto' &&
      selectedElement.type !== 'quota' &&
      selectedElement.type !== 'quotastop'
    ) {
      const updatedSelectedItem = returnOnDeleteElement(
        selectedElement.type,
        theData.selectedSubquestion,
        selectedElement.index,
        null,
        null,
        theData,
        true
      );
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: updatedSelectedItem })
      dispatch({ type: 'SAVE_QUESTION' });
    } else {
      const data = theData.originalData;
      data.elements[theData.selectedItem.section].elements.splice(
        data.elements[theData.selectedItem.section].elements.findIndex((el) => el.referenceId === theData.selectedItem.id),
        1
      );
      deleteSurveyElement(data);
    }
    dispatch({ type: 'SURVEY_SET_SUBQUESTION_INDEX', payload: 0 })
    dispatch({ type: 'SURVEY_DISPLAY_OPTIONS', payload: { type: null, display: false } });
    setDeleteModal(false);
  };

  const onCheckQuestion = (id) => {
    let elements = theData.checkedElements;
    if (theData.checkedElements.filter((el) => el === id).length !== 0) {
      elements = elements.filter((el) => el !== id);
    } else {
      elements.push(id);
    }
    dispatch({ type: 'SURVEY_DATA_CHECK_ELEMENTS', payload: elements });
  };

  const checkElementsWholeObject = (item) => {
    let elements = theData.checkedElementsObjects;
    if (theData.checkedElementsObjects.filter((el) => el.id === item.id).length !== 0) {
      elements = elements.filter((el) => el.id !== item.id);
    } else {
      elements.push(item);
    }
    dispatch({ type: 'SURVEY_DATA_CHECK_ELEMENTS_OBJECTS', payload: elements });
  }

  const updateSavedChanges = (type, id) => {
    dispatch({ type: 'LOAD_SURVEY_ELEMENT_DATA', payload: 'Saving...' });
    if (type === 'nextElement') {
      updateElements(id);
    } else if (type === 'nextTab') {
      updateElements(theData.originalSelectedItem.id);
      setToolSelected(id)
    }
  };

  const onSaveChanges = (type, id) => {
    if (theData.selectedItem.type !== 'quota') {
      const body = theData.originalSelectedItem;
      updateSurveyElement(
        `su/projects/${theData.originalSelectedItem.projectId}/surveys/${theData.originalSelectedItem.surveyId}/elements/${theData.originalSelectedItem.id}`,
        token,
        body
      ).then((res) => {
        if (res.indexElement && res.definition) {
          updateSavedChanges(type, id);
        }
      });
    } else {
      const body = theData.originalSelectedItem.quotaCells;
      updateQuotaCells(
        `projects/${theData.selectedItem.projectId}/surveys/${theData.selectedItem.surveyId}/quotas/${theData.selectedItem.quotaId}/cells`,
        token,
        body
      ).then((res) => {
        if (res) {
          updateSavedChanges(type, id);
        }
      });
    }
  };

  // Save changes (if needed) when user switches between Editor, Logic and Preview
  if (toolSelected === 0 || toolSelected === 1 || toolSelected === 2) {
    if (toolSelected !== prevToolSelected) {
      if (theData.hasChanges && theData.selectedItem) {
        onSaveChanges("nextTab", toolSelected)
      } else {
        setToolSelected(toolSelected)
      }
    }
  }

  const showOptionsDialog = (type, id) => {
    if (type === 'section') {
      setOptionsDialog({
        show: true,
        item: theData.originalData.elements[id],
        optionValues: theData.originalData.elements[id].attributes,
        id: id,
        combineFilterData: [],
      });
    }
  };

  const copySelectedQuestions = () => {
    const copyData = {
      projectId: theData.originalData.projectId,
      surveyId: theData.originalData.surveyId,
      elementIds: theData.checkedElements,
    };
    navigator.clipboard.writeText(JSON.stringify(copyData));
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `${theData.checkedElements.length} elements were copied successfully` } });
    dispatch({ type: 'SURVEY_DATA_CHECK_ELEMENTS', payload: [] });
  };

  const onSaveSectionFilter = (expression) => {
    setOptionsDialog({
      ...optionsDialog,
      optionValues: { ...optionsDialog.optionValues, filter: `${expression}` },
      combineFilterData: theData.combineFilterData,
    });
    dispatch({ type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: { display: false, filterData: [], combineFilterData: [] } });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const deleteSection = (id) => {
    if (theData.validationErrors.length > 0) {
      const deletedSectionErrorIndex = theData.validationErrors.findIndex(errorElement => errorElement.location.length === 1 && errorElement.location[0].index === (id + 1).toString())
      const updatedErrors = theData.validationErrors
      updatedErrors.splice(deletedSectionErrorIndex, 1);
      updatedErrors.forEach((error, index) => {
        if (index >= deletedSectionErrorIndex) {
          error.location[0].index = (error.location[0].index - 1).toString()
        }
      })
      dispatch({ type: "UPDATE_SURVEY_ONLY_VALIDATION_ERRORS", payload: updatedErrors })
    }

    const updatedData = JSON.parse(JSON.stringify(theData.originalData));
    updatedData.elements.splice(id, 1);
    deleteSurveyElement(updatedData);
  };

  const onExpand = (index) => {
    const data = theData.data;
    data[index].expand = !data[index].expand;
    dispatch({ type: 'ON_EXPAND_SECTION', payload: data });
  };

  const closeQuotaModal = () => {
    dispatch({
      type: 'SURVEY_DATA_SELECT_ELEMENT',
      payload: { data: returnSurveyDesignData(theData.originalData), item: null, originalItem: null, elementOptions: null },
    });
    setQuotaModal(false);
    setQuotaError('');
    setSectionIndex(0)
  };

  const updateOriginalData = (data) => {
    const body = data
    fetchPutResOrJson(`su/projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}/index`, token, body)
      .then(res => {
        if (res.status === 200) {
          if (theData.selectedItem) {
            updateElements(theData.selectedItem.id)
          } else {
            updateElements()
          }
          handleClose()
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error } });
        }
      })
  }

  const handleCopyQuestionsShortcut = () => {
    if (theData.checkedElements.length > 0) {
      editorDropdownItemClick({ item: 'Copy elements' });
    }
  }

  const handlePasteQuestionsShortcut = () => {
    if (navigator.clipboard) {
      navigator.clipboard.readText()
        .then(text => {
          const parsedCopiedData = returnValidJson(text)
          if (parsedCopiedData?.elementIds?.length > 0) {
            setPasteModal(true)
          }
        })
    }
  }

  useKeyboardShortcut(['c'], handleCopyQuestionsShortcut);
  useKeyboardShortcut(['v'], handlePasteQuestionsShortcut);

  const editorDropdownItemClick = (e) => {
    if (e.item === 'Copy elements') {
      let foundQuotaElement = false
      theData.data.every(section => {
        return section.elements.every(element => {
          if (theData.checkedElements.includes(element.id) && element.type === 'quota') {
            foundQuotaElement = true
            return false
          } return true
        });
      })
      if (foundQuotaElement) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Copying and pasting of quota elements is currently not supported" } })
      } else {
        copySelectedQuestions()
      }
    } else if (e.item === 'Paste elements') {
      setPasteModal(true)
    } else if (e.item === 'Paste pad') {
      dispatch({ type: 'SURVEY_DISPLAY_PASTE_PAD', payload: true })
    } else if (e.item === 'Duplicate') {
      if (theData.checkedElements.length > 0 || theData.selectedItem) {
        if (theData.selectedItem && theData.selectedItem.type === "quota") {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "We don't support duplicating quotas at the moment" } });
        } else {
          if (theData.selectedItem) {
            const body = {
              projectId: data.projectId,
              surveyId: data.documentId,
              elementIds: [theData.selectedItem.id]
            }
            fetchPostJson(`su/projects/${data.projectId}/surveys/${data.documentId}/prepare-paste`, token, body)
              .then(res => {
                if (res.error || res.message) {
                  dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error } });
                } else {
                  const data = returnDuplicateBodyData(res, theData.selectedItem.section, theData)
                  updateOriginalData(data)
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Question successfully duplicated" } });
                }
              })
          } else if (theData.checkedElements.length > 0) {
            const found = theData.checkedElementsObjects.find((el) => el.type === 'quota')
            if (found) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "We don't support duplicating quotas stops at the moment" } });
            } else {
              const body = {
                projectId: data.projectId,
                surveyId: data.documentId,
                elementIds: theData.checkedElements
              }
              fetchPostJson(`su/projects/${data.projectId}/surveys/${data.documentId}/prepare-paste`, token, body)
                .then(res => {
                  if (res.error || res.message) {
                    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error } });
                  } else {
                    const lastIDChecked = theData.checkedElements[theData.checkedElements.length - 1]
                    const wholeElement = theData.data.find(el => el.elements.some(item => item.id === lastIDChecked));
                    const copiedData = res
                    const data = returnDuplicateBodyData(copiedData, wholeElement.index, theData)
                    updateOriginalData(data)
                    dispatch({ type: 'SURVEY_DATA_CHECK_ELEMENTS', payload: [] });
                    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Questions successfully duplicated" } });
                  }
                })
            }
          }
        }
      } else {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Select question to duplicate" } });
      }
    } else if (e.item === "Error log") {
      dispatch({ type: 'SET_SHOW_ERROR_LOG', payload: true });
    } else if (e.item === "Refresh survey comments") {
      onRefreshComments();
    } else if (e.item === "Hide disabled questions" || e.item === "Show disabled questions") {
      dispatch({ type: 'TOGGLE_DISABLED_QUESTIONS', payload: !theData.toggleDisabledQuestions })
    } else if (e.item === "Hide logic" || e.item === "Show logic") {
      dispatch({ type: 'TOGGLE_SHOW_ELEMENT_LOGIC', payload: !theData.showElementLogic });
    }
  }

  const handleClose = () => {
    setLibraryModal(false)
    dispatch({ type: 'UPDATE_SELECTED_QUESTION', payload: {} });
  }

  //this function sets the payload of the ques
  const setPayload = (res, url) => {
    res.elements = theData.selectedItem.elements
    res.isNotCustomQuestion = true
    res.attributes = theData.selectedItem.attributes
    fetchPost(url, token, res)
  }

  const openQuotaModal = () => {
    setShowModal(false);
    setQuotaModal(true);
  }

  const onAddNewElement = (selectedElement) => {
    if (selectedElement.customQuestionId) {
      addSurveyElement(selectedElement.id, selectedElement.name, 'custom-question', null, true);
      setSectionIndex(0)
      if (openImportFromGlobalLibrary === true) {
        setOpenImportFromGlobalLibrary(false)
      }
    } else if (selectedElement.type === "ques") {
      addSurveyElement(selectedElement.id, selectedElement.name, selectedElement.type, undefined, false)
      setSectionIndex(0)
      if (openImportFromGlobalLibrary === true) {
        setOpenImportFromGlobalLibrary(false)
      }
    } else {
      if (selectedElement.name === 'Quota') {
        openQuotaModal();
      } else {
        addSurveyElement(selectedElement.id, selectedElement.name, undefined, undefined, !selectedElement.isNotCustomQuestion);
      }
    }
    handleClose();
    setShowModal({ ...showModal, show: false })
  };

  const returnTextValues = () => {
    const languages = [...theData.originalData.languages]
    const allLanguages = {}
    languages.map((el) => {
      const code = el.code
      const value = ""
      const newObj = { [code]: value }
      return { ...allLanguages, ...newObj }
    })
    return allLanguages
  }
  const onSaveList = (action) => {
    const hasWhiteSpace = (s) => {
      return /\s/g.test(s);
    }
    const reWhiteSpace = hasWhiteSpace(listNameInputValue)
    if (listNameInputValue.length <= 0) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Name must have at least one character" } });
    } else {
      if (reWhiteSpace) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "List names can not contain white space" } });
      } else {
        if (theData.originalData.lists?.find(el => el.attributes.id === listNameInputValue)) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "List with this name already exists" } });
        } else if (action === 'rename' && listNameModal.id) {
          const updatedOriginalData = JSON.parse(JSON.stringify(theData.originalData))
          const listIndex = updatedOriginalData.lists.findIndex(list => list.attributes.id === listNameModal.id)
          if (typeof listIndex === 'number' && updatedOriginalData.lists[listIndex]) {
            updatedOriginalData.lists[listIndex].attributes.id = listNameInputValue
            fetchPutJson(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, updatedOriginalData)
              .then(res => {
                if (res.error || res.message) {
                  dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
                } else {
                  res.languages.forEach(lang => { // Add language info, a compensation for backend
                    const langInfo = LanguagesList.find(el => el.code === lang.code);
                    if (langInfo) { lang.name = langInfo.name; lang.native = langInfo.native; }
                  })
                  const found = theData.selectedItem ? theData.data[theData.selectedItem.section].elements.find(el => el.selected === true) : null //Compmensation so that the CSS for slected element stays the same
                  const originalData = res;
                  const data = returnSurveyDesignData(res);
                  if (found) {
                    data[theData.selectedItem.section].elements.find(x => x.id === found.id).selected = true //Compmensation so that the CSS for slected element stays the same
                  }
                  dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } });
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "The list has been renamed successfully" } });
                  onClose()
                }
              })
          }
        } else {
          const originalData = theData.originalData;
          const newList = {
            type: "list",
            attributes: {
              id: listNameInputValue
            },
            elements: [
              {
                type: "l",
                attributes: {
                  code: 1
                },
                elements: [
                  {
                    attributes: {},
                    type: "text",
                    textValues: returnTextValues()
                  },
                ]
              }
            ]
          }
          originalData.lists.push(newList)
          const body = originalData
          fetchPutJson(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, body)
            .then(res => {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
              } else {
                res.languages.forEach(lang => { // Add language info, a compensation for backend
                  const langInfo = LanguagesList.find(el => el.code === lang.code);
                  if (langInfo) { lang.name = langInfo.name; lang.native = langInfo.native; }
                })
                const found = theData.selectedItem ? theData.data[theData.selectedItem.section].elements.find(el => el.selected === true) : null //Compmensation so that the CSS for slected element stays the same
                const originalData = res;
                const data = returnSurveyDesignData(res);
                if (found) {
                  data[theData.selectedItem.section].elements.find(x => x.id === found.id).selected = true //Compmensation so that the CSS for slected element stays the same
                }
                dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } });
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "List successfully added" } });
                onClose()
              }
            })
        }
      }
    }
  };

  const onClose = () => {
    setListNameModal({ show: false, id: null })
    setListNameInputValue("")
  };

  const onListItemMoreClick = (item, key, listId) => {
    const originalData = theData.originalData
    if (item === "Delete") {
      if (key === 0) {
        originalData.lists.shift()
      } else {
        originalData.lists.splice(key, key)
      }
      fetchPutJson(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token, originalData)
        .then(res => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          } else {
            res.languages.forEach(lang => { // Add language info, a compensation for backend
              const langInfo = LanguagesList.find(el => el.code === lang.code);
              if (langInfo) { lang.name = langInfo.name; lang.native = langInfo.native; }
            })
            const found = theData.selectedItem ? theData.data[theData.selectedItem.section].elements.find(el => el.selected === true) : null //Compmensation so that the CSS for slected element stays the same
            const originalData = res;
            const data = returnSurveyDesignData(res);
            if (found) {
              data[theData.selectedItem.section].elements.find(x => x.id === found.id).selected = true //Compmensation so that the CSS for slected element stays the same
            }
            dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } });
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "List successfully removed" } });
          }
        })
    } else if (item === 'Rename') {
      setListNameModal({ show: true, id: listId })
      setListNameInputValue(listId)
    }
  }

  const onListClick = (key) => {
    dispatch({ type: 'SET_SELECTED_LIST_INDEX', payload: key });
    dispatch({ type: 'OPEN_LIST_MODAL_VIEW_DIALOG', payload: true });
  }

  const onCloseList = () => {
    dispatch({ type: 'OPEN_LIST_MODAL_VIEW_DIALOG', payload: false });
  };

  const openSectionCSS = (id) => {
    dispatch({ type: 'SURVEY_SECTION_LEVEL_DISPLAY_SCRIPT', payload: { show: true, sectionID: id } });
  }

  const onRefreshComments = () => {
    if (theData.surveyReviews && !theData.surveyReviews.length) return;

    getSurveyReviews(`sur/reviews/${customerId}/${survey}`).then(res => {
      if (res && !res.error && !res.message) {
        dispatch({ type: 'UPDATE_SURVEY_REVIEW', payload: { surveyReviews: res } });
        if (theData.selectedItem) {
          const selectedItem = res.find((element) => element.questionId === theData.selectedItem.id);

          dispatch({ type: 'UPDATE_SURVEY_REVIEW_SELECT_ELEMENT', payload: { selectedItemReview: selectedItem } });
        }
      } else if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Error refreshing comments" } })
      }
    })
  }

  let manualFilterExpression = null;
  const filterProperty = theData.filterBuilderOptionId ? theData.filterBuilderOptionId : 'filter';
  if (optionsDialog.show) {
    if (optionsDialog.optionValues.filter) {
      manualFilterExpression = optionsDialog.optionValues.filter
    } else if (optionsDialog.item?.attributes?.filter) {
      manualFilterExpression = optionsDialog.item.attributes.filter
    }
  } else if (selectedElement?.attributes?.filterProperty) {
    manualFilterExpression = selectedElement?.attributes?.filterProperty;
  }

  return (
    <React.Fragment>
      {errorMessage && <ErrorMessage type="modal" errorMessage={errorMessage} />}
      {quotaModal && (
        <QuotaModal
          handleClose={closeQuotaModal}
          theData={theData}
          token={token}
          addSurveyElement={addSurveyElement}
          quotaRequestError={quotaError}
          clearErrorMessage={() => setQuotaError('')}
          setSectionIndex={setSectionIndex}
        />
      )}
      {theData.displayFilterBuilder &&
        <SurveyFilterBuilder
          handleClose={() => dispatch({ type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: { display: false, filterData: [], combineFilterData: [] } })}
          filterData={theData.filterData}
          surveyId={data.documentId}
          combineFilterData={theData.combineFilterData}
          option={theData.filterBuilderOptionId ? theData.filterBuilderOptionId : 'filter'}
          manualFilterExpression={manualFilterExpression}
          token={token}
          type={optionsDialog.item !== null && optionsDialog.show === true ? 'section' : 'options'}
          onSaveFilter={onSaveSectionFilter}
        />
      }
      {deleteModal && (
        <ConfirmActionModal
          show={deleteModal}
          projectName={
            theData.optionsType?.type === 'quota'
              ? 'Quota'
              : theData.selectedItem.type === 'quotastop'
                ? 'quota stop'
                : theData.elementTypes.find((el) => el.id === selectedElement.type).name.toLowerCase()
          }
          handleClose={() => handleCloseDeleteModal()}
          deleteItem={() => onDeleteElement()}
          type="element"
        />
      )}
      {optionsDialog.show && (
        <SectionOptionsDialog
          optionsDialog={optionsDialog}
          updateElements={updateElements}
          setOptionValues={(values) => setOptionsDialog({ ...optionsDialog, optionValues: values })}
          handleClose={() => setOptionsDialog({ show: false, item: null, optionValues: {}, id: null, combineFilterData: [] })}
        />
      )}
      {deleteSectionModal.show &&
        <ConfirmActionModal
          show={deleteSectionModal.show}
          projectName="section"
          handleClose={() => setDeleteSectionModal({ show: false, id: null })}
          deleteItem={() => deleteSection(deleteSectionModal.id)}
          type='element' />
      }
      {pasteModal &&
        <PasteQuestionsModal
          handleClose={() => setPasteModal(false)}
          theData={theData}
          token={token}
          updateElements={updateElements}
        />}
      {errorMessage &&
        <ErrorMessage errorMessage={errorMessage} onHide={() => setErrorMessage("")} type="modal" />
      }
      {libraryModal &&
        <GenericLibrary
          handleClose={handleClose}
          setPayload={setPayload}
          type={theData?.selectedItem?.type}
          scope="survey"
          isSelectedQuestionSurveyObject={true}
        />}
      {openImportFromGlobalLibrary &&
        <GenericLibrary
          handleClose={() => setOpenImportFromGlobalLibrary(false)}
          addingFromLibrary={true}
          importElement={(elementRowClicked) => onAddNewElement(elementRowClicked)}
          type="ques"
          scope="survey"
          isSelectedQuestionSurveyObject={true}
        />
      }
      {theData.openListModalView &&
        <SurveyDesignListModalView
          onClose={onCloseList}
        />
      }

      {theData.displaySectionCSS.show &&
        <SurveyDesignSectionCSS
          onClose={() => dispatch({ type: 'SURVEY_SECTION_LEVEL_DISPLAY_SCRIPT', payload: { show: false, sectionID: 0 } })}
          updateElements={updateElements}
        />
      }

      <div className="row no-gutters flex-grow-1 h-100 w-100 overflow-hidden survey-main">
        <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setRemoveErrorBadge(true)}>
          {!theData.displayOptions ?
            <div className="col-lg-3 survey-content border-right bg-white">
              <div className="d-flex flex-column h-100">
                <div className='build-tab-header border-bottom'>
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <h6 className='mb-0 lh-24'>{tools[toolSelected].title}</h6>
                    <div className="d-flex gap-sm">
                      <DropdownButton
                        icon="more-horizontal"
                        hideChevron={true}
                        items={itemsToRenderInDropdownEditorButton}
                        onItemClick={(e) => editorDropdownItemClick({ item: e.item.text })} className="btn btn-icon btn-transparent p-0" />

                      <Tooltip anchorElement="target" position="bottom">
                        <button type='button' id="add-button" onClick={() => setShowModal({ ...showModal, show: !showModal.show })} title="Add new element" className="btn btn-icon btn-transparent p-0">
                          <Icon type="add" className="pe-none" />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="input-group px-3 pb-3">
                    <input onChange={(e) => onSearchData(e)} type="text" name='Search' className="form-control search-input" placeholder="Search" />
                  </div>
                </div>
                <div className="question-tree h-100 overflow-auto p-3">{theData.data && theData.data.length !== 0 ?
                  <Droppable droppableId="sections" type="sections">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} className={snapshot.isDraggingOver ? 'bg-dnd-active' : 'bg-dnd'}>
                        {theData.data.map((el, sectionIndex) => (
                          <Draggable key={`section${sectionIndex}`} draggableId={`section${sectionIndex}`} index={sectionIndex}>
                            {(provided, snapshot) => (
                              <div id={el.displayIndex}>
                                <div ref={provided.innerRef} className={`${snapshot.isDragging ? 'section-dragging' : 'section-default'}`} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <div className="d-flex justify-content-between align-items-center section">
                                    <div className="d-flex flex-column">
                                      <div className="d-flex align-items-center">
                                        <span onClick={() => onExpand(sectionIndex)} className='d-flex h-100 align-items-center chevron'>
                                          <Icon className="survey-icon-fill cursor-pointer" type={el.expand ? "chevron-mini-down" : "chevron-mini-right"} />
                                        </span>
                                        <input
                                          name={`Section_${el.displayIndex}`}
                                          placeholder={`Section_${el.displayIndex}`}
                                          value={theData.originalData?.elements?.[sectionIndex]?.attributes?.id ? theData.originalData?.elements[el.index]?.attributes?.id : ""}
                                          onChange={(e) => handleChangeForSectionNames(e, el.index)}
                                          className="border-0 w-100 section-input"
                                        />
                                      </div>
                                    </div>
                                    <div className='d-flex flex-row'>
                                      <div>
                                        {el.errorMessage !== "" && !removeErrorBadge &&
                                          <ValidationErrorMessage errorMessage={el.errorMessage} />
                                        }
                                      </div>
                                      <div className="d-flex more">
                                        <ElementActions type={el.type} showOptionsDialog={showOptionsDialog} id={sectionIndex} deleteSection={(id) => setDeleteSectionModal({ show: true, id: id })} sections={theData.data} setSectionIndex={setSectionIndex} setShowModal={setShowModal} showModal={showModal} openSectionCSS={openSectionCSS} />
                                      </div>
                                    </div>
                                  </div>
                                  {el.expand ? (
                                    <SectionElements
                                      sectionIndex={sectionIndex}
                                      elements={el.elements}
                                      setQuestionSelected={setQuestionSelected}
                                      showAddElementModal={() => setShowModal({ show: true, section: true, index: sectionIndex })}
                                      sectionLength={theData.data.length}
                                      removeErrorBadge={removeErrorBadge}
                                      onCheckQuestion={onCheckQuestion}
                                      checkedElements={theData.checkedElements}
                                      checkElementsWholeObject={checkElementsWholeObject}
                                      token={token}
                                      setLibraryModal={setLibraryModal}
                                      showDeleteElementModal={() => setDeleteModal(true)}
                                      updateElements={updateElements}
                                      scrollElementIntoView={scrollElementIntoView}
                                    />
                                  ) : null}
                                </div>
                                {provided.placeholder}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  : searchError ? (
                    <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                      <span className="text-center text-muted">No elements were found</span>
                    </div>
                  ) : (
                    <InProgressOverlay type="fullscreen" theme="primary" />
                  )}
                </div>
                <div className="border-top w-100">
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <span className="mb-0 lh-24 stronger">Lists</span>
                    <Tooltip anchorElement="target" position="top">
                      <button type='button' id="add-button" onClick={() => setListNameModal({ show: true, id: null })} title="Add new list" className="btn btn-icon btn-transparent p-0">
                        <Icon type="add" className="pe-none" />
                      </button>
                    </Tooltip>
                  </div>
                  {listNameModal.show &&
                    <SurveyDesignListNameModal
                      onClose={onClose}
                      listNameInputValue={listNameInputValue}
                      setListNameInputValue={setListNameInputValue}
                      onSaveList={onSaveList}
                    />}
                  <div className='d-flex flex-column px-3 py-0 survey-list overflow-auto'>
                    <SurveyDesignListColumn
                      onListClick={onListClick}
                      onListItemMoreClick={onListItemMoreClick}
                    />
                  </div>
                </div>
              </div>
            </div>
            : null}
          <div className={"col-lg-6 d-flex flex-grow-1 survey-content question-table"}>
            <div style={{ zIndex: `${theData.selectedItem ? "999999" : null}` }}>
              {
                showModal.show &&
                <AddNewElementModal
                  handleClose={() => setShowModal({ ...showModal, show: false })}
                  addSurveyElement={addSurveyElement}
                  token={token}
                  openQuotaModal={() => {
                    setShowModal(false);
                    setQuotaModal(true);
                  }}
                  setLibraryModal={setOpenImportFromGlobalLibrary}
                  onAddNewElement={onAddNewElement}
                />
              }
              {showErrorLog &&
                <ErrorLog
                  findItemAndSetAsClicked={findItemAndSetAsClicked}
                  handleClose={() => dispatch({ type: "SET_SHOW_ERROR_LOG", payload: false })}
                />}
            </div>
            <div className="w-100 h-100 d-flex flex-column">
              <SurveyDesignTabs
                token={token}
                tabType={tabs[toolSelected].id}
                updateElements={updateElements}
                showDeleteElementModal={() => setDeleteModal(true)}
                onShowOptions={() => dispatch({ type: 'SURVEY_DISPLAY_OPTIONS', payload: { type: null, display: true } })}
                onShowScript={() => dispatch({ type: 'SURVEY_DISPLAY_SCRIPT', payload: true })}
                onShowCSS={() => dispatch({ type: 'SURVEY_DISPLAY_CSS', payload: true })}
              />
            </div>
          </div>
          {
            tabs[toolSelected].id === 'edit' && theData.selectedItem && !theData.displayOptions &&
            <NewSurveyOptions
              showDeleteElementModal={() => setDeleteModal(true)}
            />
          }
          {theData.displayScript && (
            <SurveyScript
              handleClose={() => dispatch({ type: 'SURVEY_DISPLAY_SCRIPT', payload: false })}
            />
          )}
          {theData.displayPastePad && (
            <PastePad
              updateElements={updateElements}
              addSurveyElement={addSurveyElement}
              handleClose={() => dispatch({ type: 'SURVEY_DISPLAY_PASTE_PAD', payload: false })}
            />
          )}
          {theData.displayCSS &&
            <SurveyCSS
              handleClose={() => dispatch({ type: 'SURVEY_DISPLAY_CSS', payload: false })}
            />
          }
        </DragDropContext>
      </div>
    </React.Fragment>
  );
};

import React from 'react';
//
import CustomInput from 'components/shared/CustomInput/CustomInput';
import { returnMultigridVariablePropertyName } from '../../helpers/importDataHelpers/returnMultigridVariablePropertyName/returnMultigridVariablePropertyName';

export default ({ el, onChangeHandler, index }) => {
  let colSpanValue = null;
  const variablePropertyName = returnMultigridVariablePropertyName(el)

  if ((el.multigrid || el.multilist) && el[variablePropertyName].length > 0) {
    if (el[variablePropertyName][0].values) {
      colSpanValue = el[variablePropertyName][0].values.value.length
    } else if (el[variablePropertyName][0].variable) {
      colSpanValue = el[variablePropertyName][0].variable.length
    } else {
      colSpanValue = null
    }
  } else {
    colSpanValue = null
  }

  return (
    <thead>
      <tr>
        <td colSpan="2" style={{ width: `${el.id.length + 1}ch` }}>
          <div className="form-group m-0">
            <CustomInput
              onChange={(value) => onChangeHandler(value, index, 'question', 'id')}
              type="text"
              className="form-control text-center"
              id="questionId"
              value={el.id}
            />
          </div>
        </td>
        <td colSpan={colSpanValue}>
          <div className="form-group m-0">
            <CustomInput
              onChange={(value) => onChangeHandler(value, index, 'question', 'title')}
              type="text"
              className="form-control text-center"
              id="questionTitle"
              value={el.title}
            />
          </div>
        </td>
      </tr>
    </thead>
  )
}

import { AdminUser } from "interfaces/userInterface/userInterface";

export const returnUserDisplayName = (user: AdminUser) => {
  const name = [user.firstName, user.lastName].filter(Boolean).join(' ');

  if (user.isApiUser) {
    return `${user.firstName} (API Key)`;
  }

  if (user.email) {
    return name ? `${name} (${user.email})` : user.email;
  }

  return name || "Unknown";
}

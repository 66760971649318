import { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import Logo from '../../../assets/img/walr_black.png';
import type { RootState } from '../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../FullHeightSpinner/FullHeightSpinner';
import { fetchPostJson, rsAppUrl } from '../../../services/services';

export const SelectCustomer = () => {
  document.title = "Select Customer – Walr";
  const history: TODO = useHistory()
  const { user } = useAuth0()
  const [didMount, setDidMount] = useState<boolean>(false)
  const [customers, setCustomers] = useState<{ id: string, name: string }[]>([{ name: 'Customer 123', id: 'asdasd' }, { name: 'Hello customer 2', id: 'asdasd' }])
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const customerIdArr = user ? user[`${rsAppUrl}/customerid`].split(',') : []

  useEffect(() => {
    if (!didMount && customerIdArr.length > 1 && user) {
      setDidMount(true)
      const body = customerIdArr
      fetchPostJson(`users/${user[`${rsAppUrl}/userid`]}/account-names`, token, body)
        .then((res: TODO) => {
          if (res && !res.error) {
            setCustomers(res)
          }
        })
    }
  }, [user, token, customerIdArr, didMount])

  const onSelectCustomerHandler = (customerId: string) => {
    localStorage.setItem('selectedCustomerId', customerId)
    if (localStorage.getItem('selectedCustomerId')) {
      history.push('/')
    }
  }

  return (
    <div className="d-flex flex-column vh-100 w-100">
      <div className="container flex-grow-1">
        <div className="row justify-content-center h-100">
          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="login-card border shadow-lg d-flex flex-column gap-lg w-100">
              <div className="d-flex justify-content-center mb-5">
                <img className='walr-logo' src={Logo} alt="" />
              </div>
              {
                customers.length > 0 ?
                  <Fragment>
                    <h5 className="text-center mb-0 text-primary font-weight-bold">Select a customer:</h5>
                    {
                      customers.map((customer: TODO, key: number) => (
                        <div key={key} onClick={() => onSelectCustomerHandler(customer.id)} className="card customer-card border shadow-sm p-3 text-center">
                          <h5 className="m-0 text-primary">{customer.name}</h5>
                        </div>
                      ))
                    }
                  </Fragment>
                  :
                  <FullHeightSpinner />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

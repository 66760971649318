import React, { useEffect, useState } from 'react';
import { useDebounce } from '../customHooks/useDebounce';

/*
  Cursor jumps in React controlled inputs is a common issue. This is because the cursor position is reset every time the input value changes.
  This can be fixed by managing input value internal to this component and using the debounce hook to execute external change handlers
*/

interface CustomInputProps {
  value: string;
  onChange: (value: string) => void;
  // ... other props
  [key: string] : TODO;
}

const CustomInput = ({ value, onChange, ...props }: CustomInputProps) => {
  const [inputValue, setInputValue] = useState<string>(value);

  const handleChangeEvent = useDebounce((value: string) => {
    onChange(value)
  }, 500);

  useEffect(() => {
    handleChangeEvent(inputValue)
  }, [inputValue, handleChangeEvent]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <input value={inputValue} onChange={handleChange} {...props} />
  );
};

export default CustomInput;

export type AppConfig = {
  api: {
    appBaseUrl: string;
    panelBaseUrl: string;
    surveyBaseUrl: string;
  };

  auth0: {
    domain: string;
    clientId: string;
  };

  researchStudio: {
    rsUrl: string;
    rsAppUrl: string;
  };

  subscriptionKey: string;
  appInsightsConnectionString: string;
};

// ---------------------------------------------------------

export const CONFIG: AppConfig = {

  api: {
    appBaseUrl: import.meta.env.VITE_APP_RS_API_URL,
    panelBaseUrl: import.meta.env.VITE_APP_RS_PANEL_API_URL,
    surveyBaseUrl: import.meta.env.VITE_APP_RS_SURVEY_API_URL,
  },

  auth0: {
    domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID
  },


  researchStudio: {
    rsUrl: import.meta.env.VITE_APP_RS_URL,
    rsAppUrl: import.meta.env.VITE_APP_AUTH0_NAMESPACE,
  },

  subscriptionKey: import.meta.env.VITE_APP_API_SUBSCRIPTION_KEY,
  appInsightsConnectionString: import.meta.env.VITE_APP_AZURE_INSIGTHS_CONNECTION_STRING
};

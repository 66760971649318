import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ImportModal } from '../../shared/ImportModal/ImportModal';
import EditProjectModal from '../EditProjectModal/EditProjectModal';
import type { RootState } from '../../../store/reducers/rootReducer';
import AddSurveyModal from '../../shared/AddSurveyModal/AddSurveyModal';
import ImportFileModal from '../../shared/ImportFileModal/ImportFileModal';
import type { User } from '../../../interfaces/userInterface/userInterface';
import { AddSampleModal } from '../../shared/AddSampleModal/AddSampleModal';
import AddPinboardModal from '../../shared/AddPinboardModal/AddPinboardModal';
import ShareProjectModal from '../../shared/ShareProjectModal/ShareProjectModal';
import { AddWorkflowModal } from '../../shared/AddWorkflowModal/AddWorkflowModal';
import { ProjectDetailsHeader } from './ProjectDetailsHeader/ProjectDetailsHeader';
import ConfirmActionModal from '../../shared/ConfirmActionModal/ConfirmActionModal';
import { InProgressOverlay } from '../../shared/InProgressOverlay/InProgressOverlay';
import { ProjectDocumentsTable } from './ProjectDocumentsTable/ProjectDocumentsTable';
import { ProjectSharedUsers } from '../../shared/ProjectSharedUsers/ProjectSharedUsers';
import { ImportOptionsModal } from '../../shared/Modals/ImportOptionsModal/ImportOptionsModal';
import EditProjectDocument from './ProjectDocumentsTable/EditProjectDocument/EditProjectDocument';
import type { ShareDetails } from '../../../interfaces/projectDetailsInterfaces/shareDetailsInterfaces';
import AddSurveyFromDatasetModal from '../../shared/AddSurveyFromDatasetModal/AddSurveyFromDatasetModal';
import { returnDocumentsByAccess } from '../../shared/helpers/returnDocumentsByAccess/returnDocumentsByAccess';
import { ImportLegasyProjectsModal } from '../../shared/Modals/ImportLegasyProjectsModal/ImportLegasyProjectsModal';
import { AddSurveyFromProjectModal } from '../../shared/Modals/AddSurveyFromProjectModal/AddSurveyFromProjectModal';
import { ExportProjectDocumentModal } from './ProjectDocumentsTable/ExportProjectDocumentModal/ExportProjectDocumentModal';
import returnDocumentsFromProject from '../../shared/helpers/project/returnDocumentsFromProject/returnDocumentsFromProject';
import RemoveProjectSharingConfirmModal from '../../shared/ShareProjectModal/ShareProjectContent/RemoveProjectSharingConfirmModal/RemoveProjectSharingConfirmModal';
import type { ProjectDetails as ProjectDetailsInterface, ProjectItemAction, ProjectTag } from '../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';
import { ShareProjectDeleteUserModal } from '../../shared/ShareProjectModal/ShareProjectContent/ShareProjectGeneralSettings/ShareProjectDeleteUserModal/ShareProjectDeleteUserModal';
import { fetchGetJson as getProjectById, fetchGetRes, fetchDelete as deleteDocument } from '../../../services/services';

interface ProjectModals {
  showConfirmModal: boolean
  showEditDocument: boolean
  showAddSurveyModal: boolean
  showAddSampleModal: boolean
  showImportFileModal: boolean
  showAddWorkflowModal: boolean
  showAddPinboardModal: boolean
  showAddSurveyFromDatasetModal: boolean
  showExportProjectDocumentModal: boolean
  showShareProjectModal: { show: boolean, step: string }
  showAddSurveyFromProjectModal: { survey: TODO, show: boolean }
}

export const ProjectDetails = ({ user }: { user: User }) => {
  document.title = "Project Details – Walr";
  const history = useHistory();
  const dispatch = useDispatch();
  const params: { name: string } = useParams();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { isLegacyCustomer } = useSelector((theState: RootState) => theState.userSettingsReducer);
  const { fetchProjects, showEditProjectModal } = useSelector((state: RootState) => state.projectsReducer)
  const { showDeleteUserModal } = useSelector((theState: RootState) => theState.projectExternalShareStateReducer);
  const { isQuickOptionsModalShown, isLeagasyImportModalShown } = useSelector((theState: RootState) => theState.quickImportStatusReducer);

  const [projectDetails, setProjectDetails] = useState<ProjectDetailsInterface>({ details: null, documents: null, selected: 0 })
  const [didMount, setDidMount] = useState(false)
  const [itemAction, setItemAction] = useState<ProjectItemAction>({ projectName: '', projectId: '', id: '', type: '' })
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [shareDetails, setShareDetails] = useState<ShareDetails>({ isShared: false, shareUsers: null })
  const [showRemoveSharingModal, setShowRemoveSharingModal] = useState(false);
  const [projectModals, setProjectModals] = useState<ProjectModals>({
    showConfirmModal: false,
    showAddSurveyModal: false,
    showAddSurveyFromDatasetModal: false,
    showAddSurveyFromProjectModal: { survey: null, show: false },
    showImportFileModal: false,
    showAddSampleModal: false,
    showAddWorkflowModal: false,
    showAddPinboardModal: false,
    showEditDocument: false,
    showExportProjectDocumentModal: false,
    showShareProjectModal: { show: false, step: 'access' },
  })

  const projectId: string = params.name;

  const projectModalsStateHandler = (modalType: string, value: TODO) => {
    setProjectModals({
      ...projectModals,
      [modalType]: value
    })
  }

  useEffect(() => {
    const getIsLegasyCustomer = async () => {
      const isLegacyCustomerData: TODO = await fetchGetRes('dm/sdCustomerId', token);
      if (isLegacyCustomerData.status.toString()[0] === '2' && isLegacyCustomerData.status.toString()[1] === '0') {
        dispatch(({ type: 'UPDATE_IS_LEGACY_CUSTOMER', payload: true }))
      } else {
        dispatch(({ type: 'UPDATE_IS_LEGACY_CUSTOMER', payload: false }))
      };
    }
    if (isLegacyCustomer !== true && isLegacyCustomer !== false && !didMount) {
      getIsLegasyCustomer()
    }
  }, [dispatch, token, isLegacyCustomer, didMount])

  useEffect(() => {
    if (fetchProjects || !didMount) {
      if (projectId) {
        setDidMount(true)
        dispatch({ type: 'UPDATE_FETCH_PROJECTS', payload: false })
        getProjectById(`projects/${projectId}`, token)
          .then((project: TODO) => {
            if (project && (project.message || project.error)) {
              dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: project.message ? project.message : project.error, type: 'modal' } })
            } else if (Object.entries(project).length !== 0) {
              if (project.isShared) {
                if (project.shareInformation) {
                  setShareDetails({ isShared: true, shareUsers: project.shareInformation.shareUsers, details: project.shareInformation.shareDetails })
                } else {
                  setShareDetails({ isShared: true, shareUsers: null, sharedWithDetails: project.sharedProjectInformation })
                }
              } else {
                setShareDetails({ isShared: false, shareUsers: [], details: { accessMode: 'readonly', invitedUsersCanShareInternally: false, iconUrl: '' } })
              }
              returnDocumentsFromProject(projectId, token, dispatch)
                .then((documents: TODO) => {
                  if (documents) {
                    const updatedDocuments = returnDocumentsByAccess([].concat(documents.datasetsv2, documents.datasets, documents.audiences, documents.surveys, documents.workflows, documents.files, documents.pinboards), user)
                    setProjectDetails({
                      details: project,
                      documents: updatedDocuments,
                      selected: 0
                    })
                  }
                })
            }
          })
      }
    }
  }, [didMount, token, dispatch, fetchProjects, projectId, user])

  const reloadProject = () => {
    setDidMount(false)
  }

  const showAddSurveyFromProjectModalHandler = (survey: TODO) => {
    projectModalsStateHandler("showAddSurveyFromProjectModal", { survey, show: true })
  };

  const showConfirmActionModal = (projectId: string, id: string, name: string, type: string) => {
    setItemAction({ projectName: name, projectId: projectId, id: id, type: type, description: '' })
    projectModalsStateHandler("showConfirmModal", true)
  }

  const showEditDocumentModal = (projectId: string, id: string, name: string, type: string, description: string, tags: ProjectTag[], _etag: string, dataCollectionVersion: number) => {
    setItemAction({ projectName: name, projectId: projectId, id: id, type: type, description: description, tags: tags, _etag: _etag, dataCollectionVersion: dataCollectionVersion })
    projectModalsStateHandler("showEditDocument", true)
  }

  const showExportProjectDocumentModalHandler = (projectId: string, surveyId: string, type: string) => {
    setItemAction({ ...itemAction, projectId: projectId, id: surveyId, type: type })
    projectModalsStateHandler("showExportProjectDocumentModal", true)
  }

  const onDeleteDocument = (projectId: string, id: string, type: string) => {
    if (type.includes('datasets')) {
      deleteDocument(`projects/${projectId}/${type}/${id}`, token)
        .then((res: TODO) => {
          if (res.status === 202) {
            projectModalsStateHandler("showConfirmModal", false)
            reloadProject()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The dataset has been deleted successfully' } })
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    }
    else if (type === 'surveys') {
      deleteDocument(`su/projects/${projectId}/surveys/${id}`, token)
        .then((res: TODO) => {
          if (res.status === 202) {
            projectModalsStateHandler("showConfirmModal", false)
            reloadProject()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The survey has been deleted successfully' } })
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    } else if (type === 'files') {
      deleteDocument(`/projects/${projectId}/files/${id}`, token)
        .then((res: TODO) => {
          if (res.status === 202) {
            projectModalsStateHandler("showConfirmModal", false)
            reloadProject()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The file has been deleted successfully' } })
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    } else if (type === 'workflows') {
      deleteDocument(`/projects/${projectId}/workflows/${id}`, token)
        .then((res: TODO) => {
          if (res.status === 202) {
            projectModalsStateHandler("showConfirmModal", false)
            reloadProject()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The data operation has been deleted successfully' } })
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    } else if (type === 'pinboards') {
      deleteDocument(`pb/projects/${projectId}/pinboards/${id}`, token)
        .then((res: TODO) => {
          if (res.status === 200) {
            projectModalsStateHandler("showConfirmModal", false)
            reloadProject()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The pinboard has been deleted successfully' } })
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    } else if (type === 'samples') {
      deleteDocument(`/projects/${projectId}/samples/${id}`, token)
        .then((res: TODO) => {
          if (res.status === 202) {
            projectModalsStateHandler("showConfirmModal", false)
            reloadProject()
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The sample has been deleted successfully' } })
          } else if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          }
        })
    }
  }

  const onUpdateData = (type: string) => {
    returnDocumentsFromProject(projectId, token)
      .then((documents: TODO) => {
        if (documents.message || documents.error) {
          dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: documents.message ? documents.message : documents.error, type: 'modal' } })
        } else {
          setProjectDetails({
            ...projectDetails,
            documents: [].concat(documents.datasets, documents.surveys, documents.workflows, documents.files, documents.pinboards),
          })
          if (type === 'quick-import') {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The dataset has been imported successfully.' } })
            dispatch({ type: 'COMPLETE_QUICK_IMPORT', payload: { urlProjectName: projectDetails.details?.id, projectId: projectDetails.details?.id } })
          }
        }
      })
  }

  const onOptionHandler = (legacyImport: boolean) => {
    dispatch({ type: 'DISPLAY_IMPORT_OPTIONS_MODAL', payload: false });
    if (legacyImport) {
      dispatch({ type: 'DISPLAY_LEGASY_IMPORT_MODAL', payload: true });
    } else {
      dispatch({ type: 'DISPLAY_QUICK_IMPORT_MODAL', payload: true });
    }
  };

  if (!projectDetails.details) {
    return (
      <div className="vh-100 w-100">
        <InProgressOverlay message="" className="" theme="primary" type="fullscreen" />
      </div>
    )
  }

  return (
    <section className="main project-container">
      {showEditProjectModal.show &&
        <EditProjectModal
          userData={user}
          token={token}
          projectId={projectDetails.details.id}
          onHide={() => dispatch({ type: "UPDATE_SHOW_EDIT_PROJECT_MODAL", payload: { show: false, id: null } })}
        />
      }
      {projectModals.showImportFileModal &&
        <ImportFileModal
          token={token}
          projectId={projectDetails.details.id}
          updateData={() => setDidMount(false)}
          onHide={() => projectModalsStateHandler("showImportFileModal", false)}
        />
      }
      {isLeagasyImportModalShown && (
        <ImportLegasyProjectsModal
          token={token}
          projectDocuments={projectDetails.documents || []}
          projectId={projectDetails.details.id}
          handleClose={() => dispatch({ type: 'DISPLAY_LEGASY_IMPORT_MODAL', payload: false })}
        />
      )}
      {isQuickOptionsModalShown && (
        <ImportOptionsModal handleClose={() => dispatch({ type: 'DISPLAY_IMPORT_OPTIONS_MODAL', payload: false })} handleSubmit={onOptionHandler} />
      )}
      <ImportModal
        onUpdateData={onUpdateData}
        projectDetails={projectDetails} />
      {projectModals.showShareProjectModal.show &&
        <ShareProjectModal
          user={user}
          projectId={projectId}
          shareDetails={shareDetails}
          reloadProject={reloadProject}
          projectSharingStep={projectModals.showShareProjectModal.step}
          onHide={() => projectModalsStateHandler("showShareProjectModal", { show: false, step: 'access' })} />
      }
      {projectModals.showConfirmModal &&
        <ConfirmActionModal
          type='document'
          errorMessage={errorMessage}
          projectName={itemAction.projectName}
          setError={() => { }}
          handleClose={() => projectModalsStateHandler("showConfirmModal", false)}
          deleteItem={() => onDeleteDocument(itemAction.projectId, itemAction.id, itemAction.type)}
        />
      }
      {projectModals.showAddSurveyModal &&
        <AddSurveyModal
          token={token}
          projectId={projectId}
          handleClose={() => projectModalsStateHandler("showAddSurveyModal", false)}
        />}
      {projectModals.showAddSurveyFromDatasetModal &&
        <AddSurveyFromDatasetModal
          token={token}
          handleClose={() => projectModalsStateHandler("showAddSurveyFromDatasetModal", false)}
        />}

      {projectModals.showAddSurveyFromProjectModal.show && (
        <AddSurveyFromProjectModal
          token={token}
          projectDocuments={projectDetails.documents}
          projectId={projectId}
          survey={projectModals.showAddSurveyFromProjectModal.survey}
          handleClose={() => projectModalsStateHandler("showAddSurveyFromProjectModal", { survey: null, show: false })}
        />
      )}
      {projectModals.showAddSampleModal &&
        <AddSampleModal
          token={token}
          history={history}
          projectId={projectId}
          handleClose={() => projectModalsStateHandler("showAddSampleModal", false)}
        />}
      {projectModals.showAddPinboardModal &&
        <AddPinboardModal
          token={token}
          projectId={projectId}
          onHide={() => projectModalsStateHandler("showAddPinboardModal", false)}
        />
      }
      {projectModals.showAddWorkflowModal &&
        <AddWorkflowModal
          token={token}
          history={history}
          projectId={projectId}
          handleClose={() => projectModalsStateHandler("showAddWorkflowModal", false)}
        />}
      {projectModals.showEditDocument &&
        <EditProjectDocument
          item={itemAction}
          itemAction={itemAction}
          onUpdateData={() => setDidMount(false)}
          handleClose={() => projectModalsStateHandler("showEditDocument", false)}
        />}
      {projectModals.showExportProjectDocumentModal &&
        <ExportProjectDocumentModal
          token={token}
          itemAction={itemAction}
          onHide={() => projectModalsStateHandler("showExportProjectDocumentModal", false)} />}
      {
        showDeleteUserModal.show &&
        <ShareProjectDeleteUserModal
          reloadProject={reloadProject}
          userType={showDeleteUserModal.type}
          selectedAccount={showDeleteUserModal.account}
          onHide={() => dispatch({ type: 'UPDATE_SHOW_DELETE_SHARED_USERS_MODAL', payload: { show: false, account: '', type: '' } })}
        />
      }
      {
        showRemoveSharingModal &&
        <RemoveProjectSharingConfirmModal
          reloadProject={reloadProject}
          projectId={projectDetails?.details?.id}
          onHide={() => setShowRemoveSharingModal(false)}
        />
      }

      <ProjectDetailsHeader
        projectDetails={projectDetails}
        shareDetails={shareDetails}
        user={user}
        projectModalsStateHandler={projectModalsStateHandler}
        setShowRemoveSharingModal={setShowRemoveSharingModal}
      />
      {/* <ProjectInternalUsersAccess
        onEditModal={onEditModal}
        onEditModal={() => { }}
        isProjectSharedWith={!!shareDetails?.sharedWithDetails}
        accessControl={projectDetails?.details?.accessControl}
      /> */}
      {
        !!shareDetails?.isShared && shareDetails?.shareUsers &&
        <ProjectSharedUsers
          shareDetails={shareDetails}
          onShowShareProject={(val: TODO) => projectModalsStateHandler("showShareProjectModal", val)}
        />
      }
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-lg-12 d-flex flex-column px-5 mt-3">
            <ProjectDocumentsTable
              projectId={projectId}
              shareDetails={shareDetails}
              projectDocuments={projectDetails.documents}
              setProjectDetails={setProjectDetails}
              showEditDocumentModal={showEditDocumentModal}
              showConfirmActionModal={showConfirmActionModal}
              showExportProjectDocument={showExportProjectDocumentModalHandler}
              showAddSurveyFromProjectModal={showAddSurveyFromProjectModalHandler}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const returnSessionStateType = (selectedTab: number) => {
  let stateType = ''

  switch (selectedTab) {
    case 0:
      stateType = 'toplineState'
      break;
    case 1:
      stateType = 'xtState'
      break;
    case 2:
      stateType = 'chartState'
      break;
    case 3:
      stateType = 'digState'
      break;
    case 4:
      stateType = 'qTableState'
      break;
    case 5:
      stateType = 'dataState'
      break;
    case 6:
      stateType = 'reportState'
      break;
    default:
      break;
    }

  return stateType
}
